import React, { useState } from 'react';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_INITIAL_DATA } from '../../../Root';
import {
  SEND_CONFIRMATION_LINK,
  SendConfirmationLinkData,
  SendConfirmationLinkVariables,
} from '../../../lib/graphql/mutations/auth/send-confirmation-link';
import { Query } from '../../../lib/graphql/types/query';

import { useNavigate } from 'react-router-dom';
import paths from '../../../paths';

import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';
import DotPattern from '../../../components/layout/DotPattern';
import Logo from '../../../components/layout/Logo';
import Button from '../../../components/Button';

export default function NotConfirmationPage() {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const { data } = useQuery<Query>(GET_INITIAL_DATA);
  const [getInitialData, { loading: rechecking }] = useLazyQuery<Query>(GET_INITIAL_DATA, { fetchPolicy: 'network-only' });
  const [sendConfirmationLink, { loading: submitting }] = useMutation<SendConfirmationLinkData, SendConfirmationLinkVariables>(SEND_CONFIRMATION_LINK);

  const onClickSendConfirmationLink = () => {
    if (data == null) { return; }

    sendConfirmationLink({
      variables: { input: { email: data.viewer!.email } },
      onCompleted: () => setSuccess(true),
    });
  };

  const onClickRecheck = async () => {
    const { data } = await getInitialData();

    if (data?.viewer!.confirmed === false) {
      return toast.error("Hmm... seems like your account isn't confirmed yet.");
    }

    navigate(paths.dashboard);
    toast.success('Your account has been confirmed!');
  };

  const renderContent = () => {
    if (success) {
      return (
        <div data-testid="success-container">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Sent! ✉️</h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              We just sent you a link to confirm your email address.
            </p>
          </div>

          <div className="mt-8">
            <Button size="xl" fullWidth onClick={onClickRecheck} loading={rechecking} disabled={rechecking}>
              Just finished confirming?
            </Button>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Confirm your email address</h2>
          <p className="mt-4 text-base leading-6 text-gray-500">
            You&apos;ll have to confirm your email address to continue using MyPrices.
          </p>
        </div>

        <div className="mt-8">
          <Button
            size="xl" fullWidth
            onClick={onClickSendConfirmationLink}
            loading={submitting}
            disabled={data == null || submitting}
          >
            Resend confirmation email
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Please confirm your email address</title>
      </Helmet>

      <div className="min-h-full py-16 lg:py-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="relative max-w-xl mx-auto">
          <DotPattern className="absolute left-full transform translate-x-1/2" />
          <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

          <Logo />

          {renderContent()}
        </div>
      </div>
    </React.Fragment>
  );
}
