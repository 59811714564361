import React, { useEffect, useMemo, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import {
  THEME_SETTING_UPDATE_ALL,
  ThemeSettingUpdateAllData,
  ThemeSettingUpdateAllVariables,
} from '../../../../../lib/graphql/mutations/theme/setting/update-all';
import { GET_CUSTOMIZATION_DATA } from '../../CustomizePage';
import { User } from '../../../../../lib/graphql/types/user';
import {
  ThemeSettingDataMediaType,
  ThemeSettingDataColor,
  ThemeSettingDataText,
  THEME_SETTING_DATA_MEDIA_TYPES,
} from '../../../../../lib/graphql/types/theme/setting';
import { Query } from '../../../../../lib/graphql/types/query';

import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import ColorField from '../../../../../components/fields/ColorField';
import MediaField from '../../../../../components/fields/MediaField';
import InputField from '../../../../../components/fields/InputField';
import Button from '../../../../../components/Button';

import { produce } from 'immer';

interface Props {
  user: User;
}

type ColorValue = string;
type MediaValue = {
  type: ThemeSettingDataMediaType;
  url: string;
};
type TextValue = string;

interface FormValues {
  [key: string]: ColorValue | MediaValue | TextValue;
}

export default function ThemeSettingsSection({ user }: Props) {
  const [updateSettings] = useMutation<ThemeSettingUpdateAllData, ThemeSettingUpdateAllVariables>(THEME_SETTING_UPDATE_ALL, {
    update: (cache, { data }) => {
      cache.updateQuery<Query>({ query: GET_CUSTOMIZATION_DATA }, (cachedData) => {
        return produce(cachedData!, (draft) => {
          draft.viewer!.theme!.settings = data!.themeSettingUpdateAll.themeSettings;
        });
      });

      toast.success('Theme settings updated.');
    },
  });

  const defaultValues = useMemo(() => {
    return Object.fromEntries(user.theme!.settings!.map((setting) => {
      switch (setting.data.__typename) {
        case 'ThemeSettingDataColor':
        case 'ThemeSettingDataText':
          return [setting.id, setting.data.value];
        case 'ThemeSettingDataMedia':
          return [setting.id, { type: setting.data.type, url: setting.data.url }];
        default:
          throw new Error('Unexpected setting type.');
      }
    }));
  }, [user.theme]);

  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(z.object(Object.fromEntries(user.theme!.settings!.map((setting) => {
      const resolver = (() => {
        switch (setting.type) {
          case 'TEXT':
          case 'COLOR':
            return z.string().min(1, { message: "This can't be empty." });
          case 'MEDIA':
            return z.object({
              type: z.enum(THEME_SETTING_DATA_MEDIA_TYPES),
              url: z.string(),
            });
        }
      })();

      return [setting.id, resolver];
    })))),
  });

  const resetFields = useCallback(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  useEffect(() => {
    resetFields();
  }, [form.formState.isSubmitSuccessful, resetFields]);

  const onSubmit = async (form: FormValues) => {
    type SettingInput = ThemeSettingUpdateAllVariables['input']['themeSettings'][number];
    const inputs = Object.entries(form).map<SettingInput>(([id, value]) => {
      const setting = user.theme!.settings!.find((setting) => setting.id === id)!;
      switch (setting.type) {
        case 'COLOR':
          return { id, data: { color: value as ColorValue } };
        case 'MEDIA':
          const { type, url } = value as MediaValue;
          return {
            id,
            data: { media: { type, url } },
          };
        case 'TEXT':
          return { id, data: { text: value as TextValue } };
        default:
          throw new Error('Unexpected setting type.');
      }
    });

    await updateSettings({
      variables: {
        input: { themeSettings: inputs },
      },
    });
  };

  return (
    <React.Fragment>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Theme settings</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Customize your website's theme here.
          </p>
        </div>

        <form onSubmit={form.handleSubmit(onSubmit)} className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2">
          <div className="px-4 py-6 sm:p-8 space-y-6">
            {user.theme!.settings!.map((setting) => (
              <React.Fragment key={setting.id}>
                {(() => {
                  switch (setting.type) {
                    case 'COLOR':
                      return (
                        <ColorField
                          label={setting.label}
                          placeholder={(setting.default as ThemeSettingDataColor).value}
                          name={setting.id}
                          defaultValue={defaultValues[setting.id] as ColorValue}
                          onChange={(value) => {
                            form.setValue(setting.id, value, { shouldDirty: true });
                            form.clearErrors(setting.id);
                          }}
                          required
                          error={form.formState.errors[setting.id]?.message}
                        />
                      );
                    case 'MEDIA':
                      return (
                        <MediaField
                          label={setting.label}
                          defaultValue={defaultValues[setting.id] as MediaValue}
                          onChange={(value) => {
                            form.setValue(setting.id, value, { shouldDirty: true });
                            form.clearErrors(setting.id);
                          }}
                          error={form.formState.errors[setting.id]?.message}
                        />
                      );
                    case 'TEXT':
                      return (
                        <InputField
                          label={setting.label}
                          placeholder={(setting.default as ThemeSettingDataText).value}
                          {...form.register(setting.id)}
                          type="text"
                          required
                          error={form.formState.errors[setting.id]?.message}
                        />
                      );
                    default:
                      throw new Error('Unexpected setting type.');
                  }
                })()}
              </React.Fragment>
            ))}
          </div>
          <div className="flex items-center justify-end gap-x-2 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <Button
              color="white"
              size="sm"
              disabled={!form.formState.isDirty}
              onClick={resetFields}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              loading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting || !form.formState.isDirty}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
