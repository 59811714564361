import React from 'react';

import { useMutation } from '@apollo/client';
import {
  USER_CREATE_BILLING_PORTAL_URL,
  UserCreateBillingPortalURLData,
  UserCreateBillingPortalURLVariables,
} from '../../../lib/graphql/mutations/user/create-billing-portal-url';

import { faFaceSmileRelaxed } from '@fortawesome/pro-regular-svg-icons';
import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../components/Modal';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

type ProRequiredModalState = (
  { open: true; description: string; }
    | { open: false; description?: string; }
);

type ProRequiredModalAction = (
  { type: 'OPEN'; description: string; }
    | { type: 'CLOSE'; }
);

export const proRequiredModalReducer: React.Reducer<ProRequiredModalState, ProRequiredModalAction> = (state, action) => {
  switch (action.type) {
    case 'OPEN':
      return { open: true, description: action.description };
    case 'CLOSE':
      return { ...state, open: false };
  }
};

interface Props extends ModalProps {
  description: string;
}

export default function ProRequiredModal(props: Props) {
  return (
    <Modal className="py-8" {...props} data-testid="pro-required-modal">
      <Content {...props} />
    </Modal>
  );
}

export function Content({ description, ...props }: Props) {
  const [createBillingPortalURL, { loading: creating }] = useMutation<
    UserCreateBillingPortalURLData,
    UserCreateBillingPortalURLVariables
  >(USER_CREATE_BILLING_PORTAL_URL, {
    variables: {
      input: { flow: 'SUBSCRIPTION_UPDATE' },
    },
    onCompleted: (data) => {
      window.location.assign(data!.userCreateBillingPortalUrl.url);
    },
  });

  return (
    <React.Fragment>
      <div className="flex justify-center">
        <Icon
          icon={faFaceSmileRelaxed}
          className="h-8 w-8"
        />
      </div>

      <Dialog.Title className="text-center text-lg font-medium leading-6 text-gray-900 mt-4">
        Pro plan required
      </Dialog.Title>

      <Dialog.Description className="text-center text-sm text-gray-500 mt-2">
        {description}
      </Dialog.Description>

      <div className="mt-4 flex gap-x-2">
        <Button
          className="flex-1"
          color="white"
          onClick={props.onClose}
        >
          Close
        </Button>

        <Button
          className="flex-1"
          onClick={() => createBillingPortalURL()}
          loading={creating}
          disabled={creating}
        >
          Upgrade to Pro
        </Button>
      </div>
    </React.Fragment>
  );
}
