import { gql } from '@apollo/client';

export const BOOKING_PROVIDER_DISCONNECT = gql`
  mutation BookingProviderDisconnect($input: BookingProviderDisconnectInput!) {
    bookingProviderDisconnect(input: $input) {
      enqueued
    }
  }
`;

export interface BookingProviderDisconnectData {
  bookingProviderDisconnect: {
    enqueued: boolean;
  };
}

export interface BookingProviderDisconnectVariables {
  input: {
    removeData: boolean;
  };
}
