import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments/user';
import { SERVICE_FIELDS } from '../fragments/service';

export const GET_SERVICES = gql`
  ${USER_FIELDS}
  ${SERVICE_FIELDS}

  query GetServices {
    viewer {
      ...UserFields
      services {
        ...ServiceFields
      }
    }
  }
`;
