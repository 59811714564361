import { gql } from '@apollo/client';
import { PEXELS_VIDEO_FIELDS } from '../../fragments/pexels/video';

export const SEARCH_VIDEOS = gql`
  ${PEXELS_VIDEO_FIELDS}

  query SearchPexelsVideos($query: String!) {
    pexels {
      videos(query: $query) {
        ...PexelsVideoFields
      }
    }
  }
`;

export interface SearchVideosVariables {
  query: string;
}
