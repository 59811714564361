import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../fragments/user';
import { User, AuthenticationProvider } from '../../types/user';

export const SIGN_IN_OAUTH = gql`
  ${USER_FIELDS}

  mutation SignInOAuth($input: AuthSignInOAuthInput!) {
    authSignInOAuth(input: $input) {
      authenticationToken
      user {
        ...UserFields
      }
    }
  }
`;

export interface SignInOAuthData {
  authSignInOAuth: {
    authenticationToken: string;
    user: User;
  };
}

export interface SignInOAuthVariables {
  input: {
    provider: AuthenticationProvider;
    code: string;
  };
}
