import { gql } from '@apollo/client';
import { THEME_FILE_FIELDS } from '../../../fragments/theme/file';
import { ThemeFile } from '../../../types/theme/file';

export const THEME_FILE_UPDATE = gql`
  ${THEME_FILE_FIELDS}

  mutation ThemeFileUpdate($input: ThemeFileUpdateInput!) {
    themeFileUpdate(input: $input) {
      themeFile {
        ...ThemeFileFields
      }
    }
  }
`;

export interface ThemeFileUpdateData {
  themeFileUpdate: {
    themeFile: ThemeFile;
  };
}

export interface ThemeFileUpdateVariables {
  input: {
    id: string;
    key?: string;
    content?: string;
  };
}
