import React, { useReducer, useState, useEffect, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { USER_UPDATE, UserUpdateData, UserUpdateVariables } from '../../../lib/graphql/mutations/user/update';
import { User } from '../../../lib/graphql/types/user';
import { Country } from '../../../lib/graphql/types/country';
import UserFormatter from '../../../lib/graphql/formatters/user';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { ignoreEmpty } from '../../../lib/utils/form';
import InputField from '../../../components/fields/InputField';
import CountryField from '../../../components/fields/address/CountryField';
import TimeZoneField from '../../../components/fields/address/TimeZoneField';
import FieldContainer from '../../../components/fields/FieldContainer';
import Button from '../../../components/Button';

import toast from 'react-hot-toast';
import ConnectBookingProviderModal, { connectBookingProviderModalReducer } from '../../../components/booking-provider/ConnectBookingProviderModal';
import DisconnectBookingProviderModal from '../../../components/booking-provider/DisconnectBookingProviderModal';
import CurrentPasswordModal from './CurrentPasswordModal';

interface Props {
  user: User;
}

interface FormValues {
  email: string;
  password: string | null;
  currentPassword: string | null;
  country: Country;
  timeZone: string;
}

export default function AccountSection({ user }: Props) {
  const [
    connectBookingProviderModalState,
    connectBookingProviderModalDispatch,
  ] = useReducer(connectBookingProviderModalReducer, { open: false, bookingProvider: undefined });
  const [disconnectBookingProviderModalOpen, setDisconnectBookingProviderModalOpen] = useState(false);
  const [currentPasswordModalOpen, setCurrentPasswordModalOpen] = useState(false);

  const [updateUser] = useMutation<UserUpdateData, UserUpdateVariables>(USER_UPDATE, {
    onCompleted: () => {
      toast.success('Account settings updated.');
    },
  });

  const { handleSubmit, register, formState: { errors, dirtyFields, isDirty, isSubmitting, isSubmitSuccessful }, setValue, reset, trigger } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      email: z.string().min(1, 'Please enter your email address.').email('Please enter a valid email address.'),
      password: z.string().transform(ignoreEmpty),
      currentPassword: z.string().transform(ignoreEmpty),
      country: z.string().min(1, 'Please select your country.'),
      timeZone: z.string().min(1, 'Please select your time zone.'),
    })),
  });

  const resetFields = useCallback(() => {
    const { email, country, timeZone } = user;
    reset({ email, country, timeZone, password: '', currentPassword: '' });
  }, [reset, user]);

  useEffect(() => {
    resetFields();
  }, [isSubmitSuccessful, resetFields]);

  const onSubmit = async (input: FormValues) => {
    await updateUser({
      variables: {
        input: {
          ...input,

          // If changing country, set phone/address to null.
          phone: dirtyFields.country ? null : undefined,
          address: dirtyFields.country ? null : undefined,
        },
      },
      onError: ({ graphQLErrors }) => {
        if (graphQLErrors == null) { return; }

        let { extensions } = graphQLErrors[0];
        if (extensions!.code === 'VALIDATION_FAILED' && 'currentPassword' in (extensions!.fields as any)) {
          setCurrentPasswordModalOpen(true);
        }
      },
    });
  };

  return (
    <React.Fragment>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 lg:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Account</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            These are important settings that affect your entire account.
          </p>
        </div>

        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2">
          <div className="px-4 py-6 sm:p-8 space-y-6">
            <InputField
              label="Email"
              placeholder="you@example.com"
              {...register('email')}
              type="email"
              autoComplete="off"
              required
              error={errors.email?.message}

              disabled={user.authenticationProvider != null}
              help={(() => {
                if (user.authenticationProvider != null) {
                  const provider = new UserFormatter(user).authenticationProvider;
                  return <span data-testid="email-input-help">You can't change your email address since you signed up using your {provider} account.</span>;
                }

                return null;
              })()}
            />

            {user.authenticationProvider == null && (
              <InputField
                label="New password"
                placeholder="Create a new password"
                {...register('password')}
                type="password"
                autoComplete="new-password"
                help="Leave this blank if you don't want to change your password."
                error={errors.password?.message}
              />
            )}

            <CountryField
              {...register('country')}
              required
              disabled={user.bookingProvider != null}
              help={(() => {
                if (user.bookingProvider != null) {
                  const provider = new UserFormatter(user).bookingProvider;
                  return <span data-testid="country-input-help">You can only change your country through {provider}.</span>;
                }

                return <span data-testid="country-input-help">Changing your country will reset your website's currency, contact information, & address.</span>;
              })()}
              error={errors.country?.message}
            />

            <TimeZoneField
              {...register('timeZone')}
              required
              disabled={user.bookingProvider != null}
              help={(() => {
                if (user.bookingProvider != null) {
                  const provider = new UserFormatter(user).bookingProvider;
                  return <span data-testid="time-zone-input-help">You can only change your time zone through {provider}.</span>;
                }

                return <span data-testid="time-zone-input-help">This will be the time zone used for your business hours.</span>;
              })()}
              error={errors.timeZone?.message}
            />

            {(() => {
              if (user.bookingProvider) {
                return (
                  <FieldContainer
                    label="Booking platform"
                    help="Doing this will disable online booking for your customers. Please proceed with caution."
                    data-testid="booking-platform-disconnect-container"
                  >
                    <Button onClick={() => setDisconnectBookingProviderModalOpen(true)}>
                      <img
                        className="w-5 h-5 invert mr-1"
                        src={require('../../../assets/images/icons/square.png')}
                        alt=""
                      />

                      Disconnect from Square
                    </Button>
                  </FieldContainer>
                );
              }

              return (
                <FieldContainer
                  label="Connect to a booking platform"
                  help="By connecting to Square, all your existing data will be overwritten with your Square data."
                  data-testid="booking-platform-connect-container"
                >
                  <Button
                    onClick={() => connectBookingProviderModalDispatch({
                      type: 'OPEN',
                      bookingProvider: 'SQUARE',
                    })}
                  >
                    <img
                      className="w-5 h-5 invert mr-1"
                      src={require('../../../assets/images/icons/square.png')}
                      alt=""
                    />

                    Connect to Square
                  </Button>
                </FieldContainer>
              );
            })()}
          </div>
          <div className="flex items-center justify-end gap-x-2 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <Button
              color="white"
              size="sm"
              disabled={!isDirty}
              onClick={resetFields}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              loading={isSubmitting}
              disabled={isSubmitting || !isDirty}
              onClick={async () => {
                // Validate form first before proceeding.
                const valid = await trigger();
                if (!valid) { return; }

                // If updating email or password, ask for current password first.
                if (dirtyFields.email || dirtyFields.password) {
                  return setCurrentPasswordModalOpen(true);
                }

                // Otherwise, proceed with updating user.
                handleSubmit(onSubmit)();
              }}
              data-testid="account-section-save-button"
            >
              Save
            </Button>
          </div>
        </form>
      </div>

      <ConnectBookingProviderModal
        user={user}
        open={connectBookingProviderModalState.open}
        bookingProvider={connectBookingProviderModalState.bookingProvider}
        onClose={() => connectBookingProviderModalDispatch({ type: 'CLOSE' })}
      />

      <DisconnectBookingProviderModal
        user={user}
        open={disconnectBookingProviderModalOpen}
        onClose={() => setDisconnectBookingProviderModalOpen(false)}
      />

      <CurrentPasswordModal
        open={currentPasswordModalOpen}
        onClose={() => setCurrentPasswordModalOpen(false)}
        onSubmit={({ currentPassword }) => {
          setValue('currentPassword', currentPassword);
          handleSubmit(onSubmit)();

          setCurrentPasswordModalOpen(false);
        }}
      />
    </React.Fragment>
  );
};
