import { gql } from '@apollo/client';

export const USER_CREATE_CHECKOUT_URL = gql`
  mutation UserCreateCheckoutURL($input: UserCreateCheckoutURLInput!) {
    userCreateCheckoutUrl(input: $input) {
      url
    }
  }
`;

export interface UserCreateCheckoutURLData {
  userCreateCheckoutUrl: {
    url: string;
  };
}

export interface UserCreateCheckoutURLVariables {
  input: {
    priceId: string;
  };
}
