import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { SERVICE_CREATE, ServiceCreateData, ServiceCreateVariables } from '../../../lib/graphql/mutations/service/create';
import { GET_SERVICES } from '../../../lib/graphql/queries/service';
import { Query } from '../../../lib/graphql/types/query';
import { User } from '../../../lib/graphql/types/user';
import { Service, PricingType } from '../../../lib/graphql/types/service';

import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { cleanEmpty } from '../../../lib/utils/form';
import InputField from '../../../components/fields/InputField';
import TextAreaField from '../../../components/fields/TextAreaField';
import SelectField from '../../../components/fields/SelectField';
import FieldContainer from '../../../components/fields/FieldContainer';

import toast from 'react-hot-toast';
import SlideOverForm from '../../../components/SlideOverForm';
import Button from '../../../components/Button';
import EmptyState from '../../../components/EmptyState';

import {
  faMoneyBillsSimple, faCircleInfo, faArrowDown, faArrowUp,
} from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../components/Icon';

import { range } from 'lodash';
import { produce } from 'immer';
import pluralize from 'pluralize';
import classNames from 'classnames';
import * as Currency from '../../../lib/utils/currency';

interface FormValues {
  name: string;
  description: string;
  variations: Array<{
    name: string;
    duration: {
      hours: number;
      minutes: number;
    };
    price: {
      type: PricingType;
      amount?: number | null;
      displayAs?: string | null;
    };
  }>;
}

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
  onCreate: (service: Service) => void;
}

export default function CreateServiceSlideOverForm({ user, onCreate, ...props }: Props) {
  const [createService] = useMutation<ServiceCreateData, ServiceCreateVariables>(SERVICE_CREATE);
  const { control, register, watch, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: {
      variations: [{
        name: 'Regular',
        duration: {
          hours: 0,
          minutes: 0,
        },
        price: {
          type: 'FIXED',
          amount: null,
          displayAs: null,
        },
      }],
    },
    resolver: zodResolver(z.object({
      name: z.string().min(1, { message: 'Please enter a name for this price.' }),
      description: z.string().nullable(),
      variations: z.array(
        z.object({
          name: z.string().min(1, { message: 'Please enter a name for this variation.' }),
          duration: z.object({
            hours: z.coerce.number(),
            minutes: z.coerce.number(),
          }).refine((duration) => duration.hours > 0 || duration.minutes > 0, 'Please enter a valid duration for this variation.'),
          price: z.object({
            type: z.string(),
            amount: z.coerce.number().nullish(),
            displayAs: z.string().nullish().transform(cleanEmpty),
          }).superRefine((price, context) => {
            switch (price.type as PricingType) {
              case 'FIXED':
                if (price.amount == null || price.amount < 0) {
                  return context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please enter a valid price for this variation.',
                  });
                }

                break;
              case 'VARIABLE':
                if (price.displayAs == null) {
                  return context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please enter a valid price display for this variation.',
                    path: ['displayAs'],
                  });
                }

                break;
            }
          }),
        }),
      ).nonempty({ message: 'Please add at least one variation to continue.' }),
    })),
  });

  const variationFields = useFieldArray({ control, name: 'variations' });

  // Reset form on open
  useEffect(() => {
    if (props.open) { reset(); }
  }, [props.open, reset]);

  const renderVariations = () => {
    if (variationFields.fields.length === 0) {
      return (
        <EmptyState
          className="py-4"
          title="Variations required"
          text="Please add at least one variation to continue."
        />
      );
    }

    return (
      <div className="space-y-2">
        {variationFields.fields.map((field, index) => {
          const errors = (() => {
            if (formState.errors.variations == null) { return; }
            if (formState.errors.variations.at == null) { return; }

            return formState.errors.variations.at(index);
          })();

          return (
            <div key={field.id} data-testid="variation-form" className="border border-gray-300 rounded-md shadow-sm p-4 space-y-4">
              <InputField
                label="Name"
                placeholder="Variation"
                {...register(`variations.${index}.name`)}
                type="text"
                required
                error={errors?.name?.message}
                data-testid="variation-name-input"
              />

              <FieldContainer label="Pricing" error={errors?.price?.message}>
                <div className="flex gap-x-2">
                  <div className="flex-1">
                    <SelectField
                      {...register(`variations.${index}.price.type`)}
                      data-testid="variation-price-type-input"
                    >
                      <option value="FIXED">Fixed</option>
                      <option value="VARIABLE">Variable</option>
                    </SelectField>
                  </div>

                  {watch(`variations.${index}.price.type`) === 'FIXED' && (
                    <div className="flex-1">
                      <InputField
                        icon={faMoneyBillsSimple}
                        placeholder="19.99"
                        type="number"
                        step="0.01"
                        {...register(`variations.${index}.price.amount`)}
                        required
                        data-testid="variation-price-amount-input"
                      />
                    </div>
                  )}
                </div>
              </FieldContainer>

              <InputField
                icon={faCircleInfo}
                label="Price display"
                placeholder={(() => {
                  if (watch(`variations.${index}.price.type`) === 'FIXED') {
                    return `${Currency.format(19.99, user.currency)} per set`;
                  }

                  return 'Varies';
                })()}
                type="text"
                {...register(`variations.${index}.price.displayAs`)}
                error={errors?.price?.displayAs?.message}
                help={(
                  <span>
                    {watch(`variations.${index}.price.type`) === 'FIXED' && (
                      <React.Fragment>
                        <span>Optional.</span>&nbsp;
                      </React.Fragment>
                    )}

                    Describe how pricing works to your customers.
                  </span>
                )}
                data-testid="variation-price-display-as-input"
              />

              <FieldContainer label="Duration" error={errors?.duration?.message}>
                <div className="flex gap-x-2">
                  <div className="flex-1">
                    <SelectField
                      {...register(`variations.${index}.duration.hours`)}
                      data-testid="variation-duration-hours-input"
                    >
                      {range(24).map((h) => (
                        <option key={h} value={h}>{h} {pluralize('hours', h)}</option>
                      ))}
                    </SelectField>
                  </div>

                  <div className="flex-1">
                    <SelectField
                      {...register(`variations.${index}.duration.minutes`)}
                      data-testid="variation-duration-minutes-input"
                    >
                      {range(0, 60, 5).map((m) => (
                        <option key={m} value={m}>{m} {pluralize('minutes', m)}</option>
                      ))}
                    </SelectField>
                  </div>
                </div>
              </FieldContainer>

              <div className="flex justify-between">
                <div>
                  <Button size="sm" color="red" onClick={() => variationFields.remove(index)} data-testid="variation-delete-button">
                    Delete
                  </Button>
                </div>

                <div className="flex space-x-2">
                  <Button
                    title="Move down"
                    size="sm"
                    color="white"
                    onClick={() => variationFields.move(index, index + 1)}
                    disabled={index === variationFields.fields.length - 1}
                    className={classNames({ 'cursor-not-allowed bg-gray-50': index === variationFields.fields.length - 1 })}
                    data-testid="variation-move-down-button"
                  >
                    <span className="sr-only">Move down</span>
                    <Icon icon={faArrowDown} className="w-3.5 h-3.5" />
                  </Button>

                  <Button
                    title="Move up"
                    size="sm"
                    color="white"
                    onClick={() => variationFields.move(index, index - 1)}
                    disabled={index === 0}
                    className={classNames({ 'cursor-not-allowed bg-gray-50': index === 0 })}
                    data-testid="variation-move-up-button"
                  >
                    <span className="sr-only">Move up</span>
                    <Icon icon={faArrowUp} className="w-3.5 h-3.5" />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const onClickAddVariation = () => variationFields.append({
    name: '',
    duration: {
      hours: 0,
      minutes: 0,
    },
    price: {
      type: 'FIXED',
      amount: null,
      displayAs: null,
    },
  });

  const onSubmit = async (input: FormValues) => {
    // Clean up input
    input = produce(input, (draft) => {
      draft.variations = draft.variations.map((variation) => {
        switch (variation.price.type) {
          case 'VARIABLE':
            // Remove price.amount from any variable variation.
            return produce(variation, (draft) => { draft.price.amount = null; });
          default:
            return variation;
        }
      });
    });

    await createService({
      variables: {
        input: {
          name: input.name,
          description: input.description,
          variations: input.variations.map((variation) => ({
            name: variation.name,
            duration: (variation.duration.hours * 60 * 60 * 1_000) + (variation.duration.minutes * 60 * 1_000),
            price: {
              type: variation.price.type,
              amount: variation.price.amount && variation.price.amount * 100,
              displayAs: variation.price.displayAs,
            },
          })),
        },
      },
      update: (cache, { data }) => {
        cache.updateQuery<Query>({ query: GET_SERVICES }, (cachedData) => {
          return produce(cachedData!, (draft) => {
            draft.viewer!.services!.push(data!.serviceCreate.service);
          });
        });

        props.onClose();
        onCreate(data!.serviceCreate.service);

        toast.success('All set! This price will display on your website now.');
      },
    });
  };

  return (
    <SlideOverForm
      {...props}
      title="Create price"
      subtitle="This price will show up on your website."
      onSubmit={handleSubmit(onSubmit)}
      buttonsClassName="justify-end"
      buttons={(
        <React.Fragment>
          <Button color="white" onClick={props.onClose} data-testid="close-button">Cancel</Button>
          <Button color="black" type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting} data-testid="submit-button">
            Create price
          </Button>
        </React.Fragment>
      )}
    >
      <div className="space-y-6">
        <InputField
          label="Name"
          placeholder="Really awesome service"
          {...register('name')}
          type="text"
          required
          error={formState.errors.name?.message}
          data-testid="name-input"
        />

        <TextAreaField
          label="Description"
          placeholder="Describe your really awesome service..."
          rows={4}
          {...register('description')}
          error={formState.errors.description?.message}
          data-testid="description-input"
        />

        <FieldContainer label="Variations">
          {renderVariations()}

          <Button fullWidth className="mt-2" onClick={onClickAddVariation}>
            Add variation
          </Button>
        </FieldContainer>
      </div>
    </SlideOverForm>
  );
}
