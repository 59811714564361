import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import paths from '../../../paths';
import type { LocationState } from '../../auth/SignOutPage';

import DeleteAccountModal from '../../../components/auth/DeleteAccountModal';

export default function Footer() {
  const navigate = useNavigate();

  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  return (
    <React.Fragment>
      <footer className="max-w-7xl mx-auto px-6 pt-12 pb-24">
        <nav className="flex justify-center space-x-6 sm:space-x-12" aria-label="Footer">
          <Item
            onClick={() => navigate(paths.auth.signOut, {
              state: { validInitiation: true } as LocationState,
            })}
          >
            Sign out
          </Item>

          <Item onClick={() => setDeleteAccountModalOpen(true)}>
            Delete your account
          </Item>
        </nav>
      </footer>

      <DeleteAccountModal
        open={deleteAccountModalOpen}
        onClose={() => setDeleteAccountModalOpen(false)}
      />
    </React.Fragment>
  );
}

function Item(props: React.ComponentProps<'button'>) {
  return (
    <button {...props} className="text-sm leading-6 text-gray-600 hover:text-gray-900" />
  );
}
