import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { Country } from '../../lib/graphql/types/country';

import { useMaskito } from '@maskito/react';
import InputField, { Props as InputProps } from './InputField';

import parsePhoneNumber from 'libphonenumber-js';

const COUNTRIES: { value: Country, label: string, code: string }[] = [
  { value: 'US', label: 'US 🇺🇸', code: '+1' },
  { value: 'CA', label: 'CA 🇨🇦', code: '+1' },
  { value: 'GB', label: 'UK 🇬🇧', code: '+44' },
  { value: 'AU', label: 'AU 🇦🇺', code: '+61' },
];

interface Props extends Omit<InputProps, 'defaultValue' | 'onChange'> {
  country: Country;
  defaultValue?: string | null;
  onChange?: (phone: string) => void;
}

export default function PhoneField({ country, defaultValue, onChange, ...props }: Props) {
  const format = (() => {
    switch (country) {
      case 'US':
        return '(949) 555-1234';
      case 'CA':
        return '(604) 555-1234';
      case 'GB':
        return '020 5555 1234';
      case 'AU':
        return '(02) 5555 1234';
    }
  })();

  const [value, setValue] = useState(() => {
    if (!defaultValue) { return ''; }

    // From E.164 to national format.
    const parsed = parsePhoneNumber(defaultValue, country)!;
    return parsed.formatNational();
  });

  const inputRef = useMaskito({
    options: {
      mask: (() => {
        return format.split('').map((char) => {
          if (char.match(/\d/)) {
            return /\d/;
          }

          return char;
        });
      })(),
    },
  });

  // Reset the input value when the country changes.
  useUpdateEffect(() => {
    setValue('');
    onChange?.('');
  }, [country, onChange]);

  return (
    <InputField
      ref={inputRef}
      placeholder={format}
      {...props}
      type="text"
      help={(() => {
        if (props.help != null) { return props.help; }

        switch (country) {
          case 'GB':
          case 'AU':
            return 'Please include the leading zero.';
        }
      })()}
      value={value}
      onInput={(e) => {
        const phone = e.currentTarget.value;
        setValue(phone);

        if (onChange) {
          const parsed = parsePhoneNumber(phone, country);

          // If can parse to E.164, use that, otherwise use country code + raw masked value.
          if (parsed != null) {
            onChange(parsed.number);
          } else {
            const countryCode = COUNTRIES.find((c) => c.value === country)!.code;
            onChange(phone === '' ? '' : `${countryCode} ${phone}`);
          }
        }
      }}
    />
  );
}
