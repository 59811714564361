import React, { createContext, useContext } from 'react';

import { useApolloClient } from '@apollo/client';
import { GET_INITIAL_DATA } from '../Root';
import { Query } from '../lib/graphql/types/query';
import { User } from '../lib/graphql/types/user';
import { AuthStorage } from '../lib/auth';

import { Crisp } from 'crisp-sdk-web';

interface AuthContextValue {
  /**
   * Identify the user with external services.
   */
  identify: (user: User) => Promise<void>;
  onSignIn: (data: { authenticationToken: string; user: User; }) => Promise<void>;
  onSignOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export function AuthProvider({ children }: React.PropsWithChildren) {
  const client = useApolloClient();

  const identify: AuthContextValue['identify'] = async (user) => {
    Crisp.user.setEmail(user.email, user.crispEmailSignature);
    Crisp.user.setNickname(user.businessName || user.email);
    Crisp.user.setAvatar(user.logo?.url || 'https://static.myprices.io/images/crisp-avatar-placeholder.png');
  };

  const onSignIn: AuthContextValue['onSignIn'] = async ({ authenticationToken, user }) => {
    AuthStorage.setBearerToken({ authenticationToken, user });

    // Update the cache with the authenticated user
    client.writeQuery<Query>({
      query: GET_INITIAL_DATA,
      data: { viewer: user },
    });

    identify(user);
  };

  const onSignOut = async () => {
    AuthStorage.removeBearerToken();
    await client.resetStore();

    Crisp.session.reset();
  };

  return (
    <AuthContext.Provider value={{ identify, onSignIn, onSignOut }}>
      {children}
    </AuthContext.Provider>
  );
}
