import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
  CONCIERGE_REQUEST_CREATE,
  ConciergeRequestCreateData,
  ConciergeRequestCreateVariables,
} from '../../../../lib/graphql/mutations/concierge-request/create';
import { GET_CONCIERGE_REQUESTS } from '../../../../lib/graphql/queries/concierge-request';
import { Query } from '../../../../lib/graphql/types/query';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import TextAreaField from '../../../../components/fields/TextAreaField';

import { useNavigate } from 'react-router-dom';
import paths from '../../../../paths';

import { Dialog } from '@headlessui/react';
import toast from 'react-hot-toast';
import Modal, { Props as ModalProps } from '../../../../components/Modal';
import Button from '../../../../components/Button';

import { produce } from 'immer';

interface FormValues {
  description: string;
}

export default function CreateConciergeRequestModal(props: ModalProps) {
  const navigate = useNavigate();

  const [create, { loading: submitting }] = useMutation<ConciergeRequestCreateData, ConciergeRequestCreateVariables>(CONCIERGE_REQUEST_CREATE, {
    update: (cache, { data }) => {
      cache.updateQuery<Query>({ query: GET_CONCIERGE_REQUESTS }, (cachedData) => {
        return produce(cachedData!, (draft) => {
          draft.viewer!.conciergeRequests!.unshift(data!.conciergeRequestCreate.conciergeRequest);
        });
      });

      navigate(paths.website.concierge.show(data!.conciergeRequestCreate.conciergeRequest.id));
      toast.success('We got your request!');

      props.onClose();
    },
  });

  const { reset, handleSubmit, register, formState } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      description: z.string().min(1, 'Please let us know how we can help you.'),
    })),
  });

  useEffect(() => {
    reset();
  }, [props.open, reset]);

  return (
    <Modal {...props} className="max-w-xl">
      <form onSubmit={handleSubmit((input) => create({ variables: { input } }))}>
        <div>
          <Dialog.Title className="text-base font-medium leading-6 text-gray-900">
            Send us a request
          </Dialog.Title>

          <Dialog.Description className="text-sm text-gray-500 mt-1">
            We can change <strong className="font-medium">literally anything</strong> on your website. Just let us know!
          </Dialog.Description>
        </div>

        <div className="mt-4">
          <TextAreaField
            label="Description"
            placeholder="Hi! I'd like to make the following changes to my website..."
            rows={4}
            {...register('description')}
            required
            error={formState.errors.description?.message}
          />
        </div>

        <div className="mt-4 flex gap-x-2">
          <Button
            className="flex-1"
            color="white"
            onClick={props.onClose}
          >
            Cancel
          </Button>

          <Button
            className="flex-1"
            type="submit"
            loading={submitting}
            disabled={submitting}
            data-testid="create-concierge-request-modal-submit"
          >
            Send request
          </Button>
        </div>
      </form>
    </Modal>
  );
}
