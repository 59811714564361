import React from 'react';

import { ConciergeRequest } from '../../../../lib/graphql/types/concierge-request';
import ConciergeRequestFormatter from '../../../../lib/graphql/formatters/concierge-request';
import BaseFormatter from '../../../../lib/graphql/formatters/base';

import { useNavigate } from 'react-router-dom';
import paths from '../../../../paths';

import StatusBadge from './StatusBadge';

interface Props {
  conciergeRequest: ConciergeRequest;
}

export default function ConciergeRequestCell({ conciergeRequest, ...props }: Props & React.ComponentProps<'div'>) {
  const navigate = useNavigate();

  return (
    <div
      className="cursor-pointer hover:bg-gray-50 h-20"
      onClick={() => navigate(paths.website.concierge.show(conciergeRequest.id))}
      data-testid="concierge-request-cell"
      {...props}
    >
      <div className="h-full flex items-center justify-between px-4 py-4 sm:px-6">
        <div className="flex flex-col overflow-x-hidden">
          <p className="text-sm sm:text-base font-medium">
            Request {new ConciergeRequestFormatter(conciergeRequest).referenceNumber}
          </p>

          <p className="mt-0.5 text-xs sm:text-sm text-gray-500 truncate">
            {conciergeRequest.description}
          </p>
        </div>

        <div className="h-full flex flex-col justify-between items-end ml-4">
          <StatusBadge conciergeRequest={conciergeRequest} />

          <p className="text-xs text-gray-500 whitespace-nowrap">
            {BaseFormatter.date({ value: conciergeRequest.createdAt })}
          </p>
        </div>
      </div>
    </div>
  );
}
