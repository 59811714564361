import client from '../../graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../graphql/types/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

import { toast } from 'react-hot-toast';
import { TOASTER_LOAD_TIME } from './utils';

interface Options {
  message: string;
}

// Requires that no user be signed in.
const signedOutOnlyLoader = async ({ message }: Options): Promise<Response | null> => {
  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });

  if (!data.viewer) { return null; }

  setTimeout(() => toast.error(message), TOASTER_LOAD_TIME);
  return redirect(paths.dashboard);
};

export default signedOutOnlyLoader;
