import { ConciergeRequest } from '../types/concierge-request';

export default class ConciergeRequestFormatter {
  private request: ConciergeRequest;

  constructor(request: ConciergeRequest) {
    this.request = request;
  }

  get status() {
    return ConciergeRequestFormatter.status(this.request.status);
  }

  static status(status: ConciergeRequest['status']): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'IN_PROGRESS':
        return 'In progress';
      case 'COMPLETED':
        return 'Completed';
      case 'CANCELED':
        return 'Canceled';
    }
  }

  get referenceNumber() {
    return `#${this.request.referenceNumber}`;
  }
}
