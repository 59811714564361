import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { GET_CONCIERGE_REQUESTS } from '../../../lib/graphql/queries/concierge-request';
import { Query } from '../../../lib/graphql/types/query';

import { faBellConcierge } from '@fortawesome/pro-regular-svg-icons';
import MainLayout from '../../../layouts/MainLayout';
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';
import ConciergeRequestCell from './components/ConciergeRequestCell';
import Button from '../../../components/Button';
import CreateConciergeRequestModal from './components/CreateConciergeRequestModal';

export default function ConciergePage() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { data, loading } = useQuery<Query>(GET_CONCIERGE_REQUESTS);

  return (
    <MainLayout
      title="Concierge"
      text="You can request changes to your account or website here. We'll be more than happy to help you."
      loading={loading}
      buttons={(
        <Button
          size="sm"
          onClick={() => setCreateModalOpen(true)}
        >
          Send request
        </Button>
      )}
    >
      {(() => {
        const requests = data?.viewer!.conciergeRequests! || [];

        if (requests.length === 0) {
          return (
            <EmptyState
              data-testid="no-concierge-requests"
              top={(
                <Icon
                  icon={faBellConcierge}
                  className="h-8 w-8 mx-auto"
                />
              )}
              title="No requests yet"
              text="Our concierge team can help with anything, just ask!"
              bottom={(
                <Button color="black" onClick={() => setCreateModalOpen(true)}>
                  Make your first request
                </Button>
              )}
            />
          );
        }

        return (
          <div className="bg-white shadow rounded-md overflow-hidden divide-y divide-gray-200">
            {requests.map((request) => (
              <ConciergeRequestCell key={request.id} conciergeRequest={request} />
            ))}
          </div>
        );
      })()}

      <CreateConciergeRequestModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </MainLayout>
  );
}
