import React from 'react';

import { User } from '../../../../lib/graphql/types/user';

import { faRocketLaunch } from '@fortawesome/pro-regular-svg-icons';
import EmptyState from '../../../../components/EmptyState';
import Icon from '../../../../components/Icon';
import AddServices from './cells/AddServices';
import AddOpenPeriods from './cells/AddOpenPeriods';
import AddDomain from './cells/AddDomain';
import ConfirmEmail from './cells/ConfirmEmail';

interface Props {
  user: User;
}

export default function Tasks({ user }: Props) {
  const renderContent = () => {
    if (user.tasks!.length === 0) {
      return (
        <EmptyState
          top={(
            <Icon
              icon={faRocketLaunch}
              className="h-6 w-6 text-green-500 mx-auto"
            />
          )}
          title="You're all set!"
          text="Your account is ready to go."
          data-testid="tasks-empty-state"
          className="px-4 py-8 sm:py-10"
        />
      );
    }

    return (
      <React.Fragment>
        <AddServices completed={!user.tasks!.includes('ADD_SERVICES')} />
        <AddOpenPeriods completed={!user.tasks!.includes('ADD_OPEN_PERIODS')} />

        {user.billing!.plan === 'PRO' && (
          <AddDomain completed={!user.tasks!.includes('ADD_DOMAIN')} />
        )}

        <ConfirmEmail
          email={user.email}
          completed={!user.tasks!.includes('CONFIRM_EMAIL')}
        />
      </React.Fragment>
    );
  };

  return (
    <div data-testid="tasks-container">
      <span className="block text-sm font-medium text-gray-700 mb-1">Tasks</span>
      <div className="border border-gray-200 rounded-md shadow-sm divide-y divide-gray-200 overflow-hidden">
        {renderContent()}
      </div>
    </div>
  );
}
