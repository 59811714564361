import React from 'react';

import classNames from 'classnames';

interface Props {
  className?: string;
  innerClassName?: string;

  top?: React.ReactNode;
  title: React.ReactNode;
  text: React.ReactNode;
  bottom?: React.ReactNode;

  'data-testid'?: string;
}

export default function EmptyState({ className, innerClassName, top, title, text, bottom, 'data-testid': dataTestId }: Props) {
  return (
    <div className={classNames('h-full flex justify-center items-center px-4', className)} data-testid={dataTestId}>
      <div className={classNames('text-center', innerClassName)}>
        {top && <div className="mb-2">{top}</div>}

        <h3 className="text-base font-medium text-gray-900">{title}</h3>
        <p className="mt-1 text-sm text-gray-500">{text}</p>

        {bottom && <div className="inline-block mt-6">{bottom}</div>}
      </div>
    </div>
  );
}
