import React from 'react';

import { Domain } from '../../../../../../lib/graphql/types/domain';

import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../../../../components/Icon';

import classNames from 'classnames';

interface Props {
  domain: Domain;
  onClick: () => void;
}

export default function DomainCell({ domain, onClick }: Props) {
  return (
    <div
      className="cursor-pointer hover:bg-gray-50 flex items-center justify-between px-4 py-4 sm:px-6"
      onClick={onClick}
      data-testid="domain-cell"
    >
      <div className="flex items-center space-x-3 sm:space-x-4">
        <div>
          {(() => {
            if (domain.dnsStatus.connected) {
              return (
                <Icon
                  icon={faCheckCircle}
                  className="text-green-600"
                  size="lg"
                  data-testid="domain-cell-connected-icon"
                />
              );
            }

            return (
              <Icon
                icon={faTimesCircle}
                className="text-red-600"
                size="lg"
                data-testid="domain-cell-not-connected-icon"
              />
            );
          })()}
        </div>

        <div>
          <p className="text-sm sm:text-base font-medium" data-testid="domain-cell-name">
            {domain.name}
          </p>

          <p
            className={classNames('mt-0.5 sm:mt-0 text-xs sm:text-sm', {
              'text-green-600': domain.dnsStatus.connected,
              'text-red-600': !domain.dnsStatus.connected,
            })}
            data-testid="domain-cell-dns-status"
          >
            {domain.dnsStatus.connected ? 'Connected' : 'Not connected'}
          </p>
        </div>
      </div>

      <div className="flex">
        <Icon icon={faChevronRight} className="text-gray-400" />
      </div>
    </div>
  );
}
