import React from 'react';

import { useMatches, useNavigate } from 'react-router-dom';

import { Menu } from '@headlessui/react';

import classNames from 'classnames';

export type Props = React.PropsWithChildren<{
  to?: string;
  onClick?: () => void;
}>;

export default function DropdownItem(props: Props) {
  const navigate = useNavigate();
  const matches = useMatches();
  const active = matches.find((match) => {
    return match.pathname === props.to;
  }) != null && props.to != null;

  return (
    <Menu.Item>
      <div
        onClick={() => {
          if (props.onClick) { return props.onClick(); }
          return navigate(props.to!);
        }}
        className={classNames('cursor-pointer px-4 py-2 text-sm hover:bg-gray-50 text-gray-700 focus:outline-none', {
          'bg-gray-100': active,
        })}
      >
        {props.children}
      </div>
    </Menu.Item>
  );
}
