import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../fragments/user';
import { User } from '../../types/user';
import { BookingProvider } from '../../types/booking-provider';

export const BOOKING_PROVIDER_CONNECT = gql`
  ${USER_FIELDS}

  mutation BookingProviderConnect($input: BookingProviderConnectInput!) {
    bookingProviderConnect(input: $input) {
      user {
        ...UserFields
      }
    }
  }
`;

export interface BookingProviderConnectData {
  bookingProviderConnect: {
    user: User;
  };
}

export interface BookingProviderConnectVariables {
  input: {
    provider: BookingProvider;
    code: string;
  };
}
