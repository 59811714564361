import React, { useEffect, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { USER_UPDATE, UserUpdateData, UserUpdateVariables } from '../../../lib/graphql/mutations/user/update';
import { User } from '../../../lib/graphql/types/user';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { cleanEmpty } from '../../../lib/utils/form';
import InputField from '../../../components/fields/InputField';
import Button from '../../../components/Button';

import toast from 'react-hot-toast';
import { faInstagram, faFacebook, faYelp, faGoogle } from '@fortawesome/free-brands-svg-icons';

interface Props {
  user: User;
}

interface FormValues {
  instagramUrl: string | null;
  facebookUrl: string | null;
  yelpUrl: string | null;
  googleUrl: string | null;
}

export default function ExternalLinksSection({ user }: Props) {
  const [updateUser] = useMutation<UserUpdateData, UserUpdateVariables>(USER_UPDATE, {
    onCompleted: () => {
      toast.success('External links updated.');
    },
  });

  const { reset, register, formState, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      instagramUrl: z.string().nullable().transform(cleanEmpty),
      facebookUrl: z.string().nullable().transform(cleanEmpty),
      yelpUrl: z.string().nullable().transform(cleanEmpty),
      googleUrl: z.string().nullable().transform(cleanEmpty),
    })),
  });

  const resetFields = useCallback(() => {
    const { instagramUrl, facebookUrl, yelpUrl, googleUrl } = user;
    reset({ instagramUrl, facebookUrl, yelpUrl, googleUrl });
  }, [reset, user]);

  useEffect(() => {
    resetFields();
  }, [formState.isSubmitSuccessful, resetFields]);

  const onSubmit = async (input: FormValues) => {
    await updateUser({
      variables: { input },
    });
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900">External links</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          These links will be displayed on your website.
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2">
        <div className="px-4 py-6 sm:p-8 grid grid-cols-1 sm:gap-x-4 gap-y-6 sm:gap-y-4 sm:grid-cols-2">
          <InputField
            icon={faInstagram}
            label="Instagram URL"
            placeholder="https://www.instagram.com/"
            {...register('instagramUrl')}
            type="url"
            error={formState.errors.instagramUrl?.message}
          />

          <InputField
            icon={faFacebook}
            label="Facebook URL"
            placeholder="https://www.facebook.com/"
            {...register('facebookUrl')}
            type="url"
            error={formState.errors.facebookUrl?.message}
          />

          <InputField
            icon={faYelp}
            label="Yelp URL"
            placeholder="https://www.yelp.com/biz/"
            {...register('yelpUrl')}
            type="url"
            error={formState.errors.yelpUrl?.message}
          />

          <InputField
            icon={faGoogle}
            label="Google URL"
            placeholder="https://maps.app.goo.gl/"
            {...register('googleUrl')}
            type="url"
            error={formState.errors.googleUrl?.message}
          />
        </div>
        <div className="flex items-center justify-end gap-x-2 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <Button
            color="white"
            size="sm"
            disabled={!formState.isDirty}
            onClick={resetFields}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting || !formState.isDirty}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
