import client from '../../graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../graphql/types/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

/**
 * If onboarding is incomplete, redirects to onboarding process.
*/
export const onboardedOnlyLoader = async (): Promise<Response | null> => {
  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });
  const { billing, businessName } = data.viewer!;

  if (billing == null) {
    return redirect(paths.onboarding.selectPlan);
  }

  if (businessName == null) {
    return redirect(paths.onboarding.stripeCallback);
  }

  return null;
};

export default onboardedOnlyLoader;
