import React from 'react';

import { BeatLoader } from 'react-spinners';
import Icon, { Props as IconProps } from './Icon';

import classNames from 'classnames';

interface Props {
  rounded?: boolean;
  size?: 'sm' | 'base' | 'xl' | 'custom';
  fullWidth?: boolean;
  color?: 'black' | 'gray' | 'gray-dark' | 'white' | 'red' | 'green';
  loading?: boolean;
  leftIcon?: IconProps['icon'];
  rightIcon?: IconProps['icon'];
}

function Button({
  rounded,
  size = 'base',
  fullWidth,
  color = 'black',
  loading,
  leftIcon,
  rightIcon,
  children,
  ...props
}: Props & React.ComponentProps<'button'>) {
  const nonRoundedClassNames = classNames({
    'px-3 py-2 text-sm leading-4': size === 'sm',
    'px-4 py-2 text-sm': size === 'base',
    'px-6 py-3 text-base': size === 'xl',
  });

  const roundedClassNames = classNames({
    'px-3.5 py-2 text-sm leading-4': size === 'sm',
    'px-4 py-2 text-sm': size === 'base',
    'px-6 py-3 text-base': size === 'xl',
  });

  const loaderColor = (() => {
    switch (color) {
      case 'black':
      case 'gray-dark':
      case 'red':
      case 'green':
        return 'white';
      case 'white':
      case 'gray':
        return 'black';
    }
  })();

  const iconClassNames = classNames({
    'h-3.5 w-3.5': size === 'sm',
    'h-4 w-4': size === 'base' || size === 'xl',
  });

  return (
    <button
      type="button"
      {...props}
      className={classNames('flex items-center justify-center border border-transparent font-medium whitespace-nowrap select-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2', {
        'rounded-md': !rounded,
        'rounded-full': rounded,
        'w-full': fullWidth,
        'text-white bg-black enabled:hover:bg-neutral-900 focus-visible:outline-black': color === 'black',
        'bg-gray-100 enabled:hover:bg-gray-200 focus-visible:outline-gray-100': color === 'gray',
        'text-white bg-neutral-900 enabled:hover:bg-neutral-800 focus-visible:outline-neutral-900': color === 'gray-dark',
        'bg-white !border-gray-300 enabled:hover:bg-gray-50 focus-visible:outline-black': color === 'white',
        'text-white bg-red-600 enabled:hover:bg-red-700 focus-visible:outline-red-600': color === 'red',
        'text-white bg-emerald-700 enabled:hover:bg-emerald-800 focus-visible:outline-emerald-700': color === 'green',
        'cursor-not-allowed opacity-50': props.disabled,
      }, rounded ? roundedClassNames : nonRoundedClassNames, props.className)}
    >
      {(leftIcon && !loading) && <Icon icon={leftIcon} className={classNames('-ml-1 mr-2', iconClassNames)} />}

      {(() => {
        if (loading) {
          return (
            <BeatLoader
              color={loaderColor}
              size={8}
              margin={0}
              className={classNames({
                'my-1': size === 'sm',
                'my-1.5': size === 'base',
                'my-2': size === 'xl',
              })}
            />
          );
        }

        return children;
      })()}

      {(rightIcon && !loading) && <Icon icon={rightIcon} className={classNames('ml-2', iconClassNames)} />}
    </button>
  );
}

export default Button;
