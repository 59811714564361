import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { MYPRICES_GRAPHQL_ENDPOINT } from '../config';
import { AuthStorage } from '../auth';

import { toast } from 'react-hot-toast';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message }) => {
      if (extensions?.code) { toast.error(message); }
    });
  }

  if (networkError) {
    toast.error('Oops! Something\'s up with our servers. Please check back in a bit.');
  }
});

const httpLink = createUploadLink({
  uri: MYPRICES_GRAPHQL_ENDPOINT,
  fetch(uri, options) {
    if (AuthStorage.bearerToken) {
      options!.headers = {
        ...options!.headers,
        authorization: `Bearer ${window.btoa(AuthStorage.bearerToken)}`,
      };
    }

    return fetch(uri, options);
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
});

export default client;
