import React from 'react';
import { useAsync } from 'react-use';

import { gql, useQuery } from '@apollo/client';
import { USER_FIELDS } from './lib/graphql/fragments/user';
import { Query } from './lib/graphql/types/query';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import paths from './paths';

import { useAuth } from './context/auth';
import Loading from './components/Loading';

export const GET_INITIAL_DATA = gql`
  ${USER_FIELDS}

  query GetInitialData {
    viewer {
      ...UserFields
    }
  }
`;

export default function Root() {
  const auth = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const { data, loading } = useQuery<Query>(GET_INITIAL_DATA);

  useAsync(async () => {
    if (data == null) { return; }

    const { viewer } = data;
    if (viewer == null) {
      await auth.onSignOut();
    } else {
      await auth.identify(viewer);
    }

    // Redirect to appropriate initial page if visiting `/` route.
    if (location.pathname === paths.root) {
      if (viewer != null) {
        navigate(paths.dashboard);
      } else {
        navigate(paths.auth.signIn);
      }
    }
  }, [data, auth, location, navigate]);

  if (loading) {
    return <Loading className="h-full" />;
  }

  return <Outlet />;
}
