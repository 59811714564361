import React, { useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import {
  USER_CREATE_UPDATE_PAYMENT_METHOD_URL,
  UserCreateUpdatePaymentMethodURLData,
  UserCreateUpdatePaymentMethodURLVariables,
} from '../../../lib/graphql/mutations/user/create-update-payment-method-url';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/types/query';

import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from '../../../paths';

import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import Loading from '../../../components/Loading';
import DotPattern from '../../../components/layout/DotPattern';
import Logo from '../../../components/layout/Logo';
import Button from '../../../components/Button';

export default function TrialEndedPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const waitingForActivation = searchParams.get('success') === 'true';

  const [createUrl, { loading: creating }] = useMutation<
    UserCreateUpdatePaymentMethodURLData,
    UserCreateUpdatePaymentMethodURLVariables
  >(USER_CREATE_UPDATE_PAYMENT_METHOD_URL, {
    variables: {
      input: {
        cancelPath: paths.subscription.trialEnded,
        successPath: `${paths.subscription.trialEnded}?success=true`,
      },
    },
  });

  const { data, loading } = useQuery<Query>(GET_INITIAL_DATA, {
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  useEffect(() => {
    if (data == null) { return; }

    if (data.viewer!.billing!.active) {
      toast.success("Thanks! You're all set.");
      navigate(paths.dashboard);
    }
  }, [data, navigate]);

  if (loading || waitingForActivation) { return <Loading className="h-full" />; }

  const onClickUpdatePaymentMethod = () => {
    createUrl({
      onCompleted: (data) => {
        window.location.assign(data!.userCreateUpdatePaymentMethodUrl.url);
      },
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Trial ended</title>
      </Helmet>

      <div className="min-h-full py-16 lg:py-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="relative max-w-lg mx-auto">
          <DotPattern className="absolute left-full transform translate-x-1/2" />
          <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

          <Logo />

          <div>
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 mt-12 lg:mt-8">
                Your trial has ended!
              </h2>

              <p className="mt-4 text-base leading-6 text-gray-500">
                We hope you loved everything we have to offer! Please update your payment
                method to start your subscription.
              </p>
            </div>

            <div className="mt-8 space-y-2">
              <Button
                size="xl" fullWidth
                onClick={onClickUpdatePaymentMethod}
                disabled={creating} loading={creating}
              >
                Update payment method
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
