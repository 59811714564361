import React from 'react';

import { faCircle, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../../components/Icon';

import classNames from 'classnames';

interface Props {
  title: string;
  text: string;
  completed: boolean;
}

export default function TaskCell({ title, text, completed, ...props }: Props & React.ComponentProps<'div'>) {
  return (
    <div
      className={classNames({
        'cursor-pointer hover:bg-gray-50': !completed,
        'cursor-not-allowed': completed,
      })}
      data-testid="task-cell"
      {...props}
      onClick={(event) => {
        if (completed) { return; }

        props.onClick?.(event);
      }}
    >
      <div className="flex items-center justify-between px-4 py-4 sm:px-6">
        <div className="flex items-center space-x-3 sm:space-x-4">
          <div>
            {(() => {
              if (completed) {
                return (
                  <Icon
                    icon={faCheckCircle}
                    className="text-green-600"
                    size="lg"
                    data-testid="task-cell-complete-icon"
                  />
                );
              }

              return (
                <Icon
                  icon={faCircle}
                  className="text-gray-400"
                  size="lg"
                  data-testid="task-cell-incomplete-icon"
                />
              );
            })()}
          </div>

          <div>
            <p
              className={classNames('text-sm sm:text-base font-medium', {
                'line-through': completed,
              })}
              data-testid="task-cell-title"
            >
              {title}
            </p>

            <p
              className={classNames('mt-0.5 sm:mt-0 text-xs sm:text-sm text-gray-500', {
                'line-through': completed,
              })}
              data-testid="task-cell-text"
            >
              {text}
            </p>
          </div>
        </div>

        {!completed && (
          <div className="flex items-center">
            <Icon
              icon={faChevronRight}
              className="text-gray-400"
              data-testid="task-cell-chevron-icon"
            />
          </div>
        )}
      </div>
    </div>
  );
}
