import React from 'react';

import FieldContainer, { Props as FieldContainerProps } from './FieldContainer';
import classNames from 'classnames';

export interface Props extends FieldContainerProps, React.ComponentProps<'select'> {
  inputSize?: 'base' | 'lg';
}

const SelectField = React.forwardRef<HTMLSelectElement, Props>(({
  label,
  error,
  help,
  inputSize = 'base',
  ...props
}, ref) => {
  const id = props.id || props.name;

  return (
    <FieldContainer id={id} label={label} error={error} help={help}>
      <select
        ref={ref}
        id={id}
        {...error && { 'aria-invalid': true, 'aria-errormessage': id ? `${id}-error` : undefined }}
        {...help && { 'aria-describedby': id ? `${id}-description` : undefined }}
        {...props}
        className={classNames('appearance-none block w-full pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black rounded-md shadow-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:opacity-100', {
          'py-2 sm:text-sm': inputSize === 'base',
          'py-3': inputSize === 'lg',
          'border-red-300 text-red-900 placeholder:text-red-300 focus:ring-red-500 focus:border-red-500': error != null,
        }, props.className)}
      />
    </FieldContainer>
  );
});

export default SelectField;
