import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';

interface Props {
  sortId: string;
  sortEnabled?: boolean;
}

export default function SortableCell({ sortId, sortEnabled, children }: React.PropsWithChildren<Props>) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: sortId,
    disabled: !sortEnabled,
  });

  return (
    <div
      ref={setNodeRef}
      className={classNames('relative focus:outline-none', { 'z-10': isDragging })}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      {...attributes} {...listeners}
    >
      <div
        className={classNames('select-none transition-all duration-200', {
          'opacity-100 bg-white scale-[1.02]': isDragging,
          'cursor-pointer': !sortEnabled,
          'cursor-grab': sortEnabled && !isDragging,
          'cursor-grabbing': sortEnabled && isDragging,
        })}
      >
        {children}
      </div>
    </div>
  );
}
