import { gql } from '@apollo/client';

export const DOMAIN_DELETE = gql`
  mutation DomainDelete {
    domainDelete {
      id
    }
  }
`;

export interface DomainDeleteData {
  domainDelete: {
    id: string;
  };
}
