import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { USER_FIELDS } from '../../lib/graphql/fragments/user';
import { Query } from '../../lib/graphql/types/query';

import MainLayout from '../../layouts/MainLayout';
import Tasks from './components/Tasks';
import WebsiteStatus from './components/WebsiteStatus';

import dayjs from 'dayjs';

export const GET_DASHBOARD_DATA = gql`
  ${USER_FIELDS}

  query GetDashboardData {
    viewer {
      ...UserFields
      tasks
    }
  }
`;

export default function DashboardPage() {
  const { data, loading } = useQuery<Query>(GET_DASHBOARD_DATA, {
    fetchPolicy: 'network-only',
  });

  return (
    <MainLayout
      title="Dashboard"
      text={(() => {
        const currentHour = dayjs().hour();

        switch (true) {
          case (currentHour < 3):
            return 'Good evening!';
          case (currentHour < 12):
            return 'Good morning!';
          case (currentHour < 17):
            return 'Good afternoon!';
          case (currentHour < 24):
            return 'Good evening!';
        }
      })()}
      loading={loading}
    >
      <div className="flex flex-col h-full" data-testid="dashboard-container">
        <div className="space-y-4">
          {data?.viewer!.tasks!.length !== 0 && <Tasks user={data?.viewer!} />}
        </div>

        <div className="flex-1 pt-16">
          <WebsiteStatus url={data?.viewer!.websiteUrl!} />
        </div>
      </div>
    </MainLayout>
  );
}
