import React from 'react';

import { useMutation } from '@apollo/client';
import {
  USER_CREATE_CHECKOUT_URL,
  UserCreateCheckoutURLData,
  UserCreateCheckoutURLVariables,
} from '../../../lib/graphql/mutations/user/create-checkout-url';

import Button from '../../../components/Button';
import Badge from '../../../components/Badge';

import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../../components/Icon';

import classNames from 'classnames';
import * as Currency from '../../../lib/utils/currency';

import { Price } from '../../../lib/graphql/types/price';

interface Props {
  price: Price;
}

const LITE_FEATURES = [
  'An elegant website with pricing information for your customers',
  'Book by phone',
  'Instagram gallery integration',
  'Unique, from-scratch website design starting at $500',
];

const PRO_FEATURES = [
  'Everything in Lite',
  'Square integration for booking & payments',
  'Connect a custom domain',
  'Custom website pages',
  'Website code editor',
  'Google Analytics integration',
  'Facebook Chat Plugin integration',
  'Dedicated engineer to make minor website changes',
];

export default function Plan({ price }: Props) {
  const [createCheckoutURL, { loading: creating }] = useMutation<
    UserCreateCheckoutURLData,
    UserCreateCheckoutURLVariables
  >(USER_CREATE_CHECKOUT_URL, {
    variables: { input: { priceId: price.id } },
  });

  const onClickCreateCheckout = () => {
    createCheckoutURL({
      onCompleted: (data) => {
        window.location.assign(data!.userCreateCheckoutUrl.url);
      },
    });
  };

  const features = (() => {
    switch (price.plan) {
      case 'LITE':
        return LITE_FEATURES;
      case 'PRO':
        return PRO_FEATURES;
    }
  })();

  return (
    <div
      className={classNames(
        'divide-y divide-gray-200 rounded-lg border',
        { 'border-transparent': price.plan !== 'PRO' },
        { 'border-gray-200 shadow-sm': price.plan === 'PRO' },
      )}
    >
      <div className="p-6">
        <div className="h-10 flex justify-between">
          <div>
            {price.plan === 'PRO' && <Badge className="animate-pulse">Most popular!</Badge>}
          </div>

          <div>
            <Badge>
              {Currency.flag(price.currency)} {price.currency}
            </Badge>
          </div>
        </div>

        <h2 className="text-lg font-medium leading-6 text-gray-900">{price.name}</h2>
        <p className="mt-2 text-sm text-gray-500">{price.description}</p>
        <div className="mt-6">
          <span className="text-4xl font-bold tracking-tight text-gray-900">
            {Currency.format(price.unitAmount, price.currency, { fromCents: true, hideZeroCents: true })}
          </span>&nbsp;
          <span className="text-base text-gray-500">
            {price.interval === 'MONTH' ? '/mo' : '/yr' }
          </span>
        </div>
        <Button
          fullWidth size="xl" className="mt-6"
          onClick={onClickCreateCheckout}
          loading={creating} disabled={creating}
        >
          Start trial
        </Button>
      </div>
      <div className="px-6 pt-6 pb-8">
        <h3 className="text-sm font-medium text-gray-900">What&apos;s included</h3>
        <ul className="mt-6 space-y-4">
          {features.map((feature) => (
            <li key={feature} className="flex space-x-3">
              <Icon icon={faCheck} className="h-4 w-4 flex-shrink-0 text-green-500" />
              <span className="text-sm text-gray-500">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
