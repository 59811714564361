import { User, AuthenticationProvider } from '../types/user';
import { BookingProvider } from '../types/booking-provider';

export default class UserFormatter {
  private user: User;

  constructor(user: User) {
    this.user = user;
  }

  get authenticationProvider() {
    if (this.user.authenticationProvider == null) { return null; }

    return UserFormatter.authenticationProvider(this.user.authenticationProvider);
  }

  static authenticationProvider(provider: AuthenticationProvider): string {
    switch (provider) {
      case 'GOOGLE':
        return 'Google';
    }
  }

  get bookingProvider() {
    if (this.user.bookingProvider == null) { return null; }

    return UserFormatter.bookingProvider(this.user.bookingProvider);
  }

  static bookingProvider(provider: BookingProvider): string {
    switch (provider) {
      case 'SQUARE':
        return 'Square';
    }
  }
}
