import React, { useEffect } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';
import { USER_DELETE } from '../../lib/graphql/mutations/user/delete';
import { GET_INITIAL_DATA } from '../../Root';
import { Query } from '../../lib/graphql/types/query';

import { useLocation, useNavigate } from 'react-router-dom';
import paths from '../../paths';

import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import Loading from '../../components/Loading';
import EmptyState from '../../components/EmptyState';
import Icon from '../../components/Icon';
import Button from '../../components/Button';

export interface LocationState {
  validInitiation: boolean;
}

export default function DeleteAccountPage() {
  const locationState: LocationState | undefined = useLocation().state;
  const navigate = useNavigate();

  const [pollInitialData, { data }] = useLazyQuery<Query>(GET_INITIAL_DATA, {
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  const [deleteAccount] = useMutation(USER_DELETE);
  useEffect(() => {
    if (!locationState?.validInitiation) {
      return navigate(paths.root);
    }

    // Enqueue the account deletion.
    deleteAccount();

    // Poll until the user is deleted.
    pollInitialData();
  }, [locationState, navigate, deleteAccount, pollInitialData]);

  if (data != null && data.viewer == null) {
    return (
      <EmptyState
        innerClassName="max-w-2xl px-4"
        top={(
          <Icon
            icon={faCircleCheck}
            className="h-8 w-8 text-green-500 mx-auto"
          />
        )}
        title="Account deleted."
        text="We're sorry to see you go. If you change your mind, you can always sign up again."
        bottom={(
          <Button color="black" onClick={() => navigate(paths.root)}>
            Continue
          </Button>
        )}
      />
    );
  }

  return (
    <Loading
      text="Deleting your account..."
      className="h-full"
    />
  );
}
