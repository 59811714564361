import client from '../../graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../graphql/types/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

import { toast } from 'react-hot-toast';
import { TOASTER_LOAD_TIME } from './utils';

import signedInOnlyLoader from './signed-in-only';
import confirmedOnlyLoader from './confirmed-only';
import onboardedOnlyLoader from './onboarded-only';

// Requires that user has no current responsibilities.
// This could be email confirmation, onboarding incomplete,
// subscription payment due, etc.
export const completeLoader = async ({ requiresPro } = { requiresPro: false }): Promise<Response | null> => {
  const signedInOnlyLoaderResponse = await signedInOnlyLoader();
  if (signedInOnlyLoaderResponse) { return signedInOnlyLoaderResponse; }

  const confirmedOnlyLoaderResponse = await confirmedOnlyLoader();
  if (confirmedOnlyLoaderResponse) { return confirmedOnlyLoaderResponse; }

  const onboardedOnlyLoaderResponse = await onboardedOnlyLoader();
  if (onboardedOnlyLoaderResponse) { return onboardedOnlyLoaderResponse; }

  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });
  const { previousStatus, status, plan } = data.viewer!.billing!;

  // If subscription changed from trialing to past due, redirect to appropriate page.
  if (previousStatus === 'TRIALING' && status === 'PAST_DUE') {
    return redirect(paths.subscription.trialEnded);
  }

  if (requiresPro && plan !== 'PRO') {
    setTimeout(() => toast.error('Please upgrade to the Pro plan to use this feature.'), TOASTER_LOAD_TIME);
    return redirect(paths.dashboard);
  }

  return null;
};

export default completeLoader;
