import React from 'react';

import SelectField, { Props as SelectFieldProps } from '../SelectField';

export const TIME_ZONES = ['Etc/UTC', ...(Intl as any).supportedValuesOf('timeZone')];

const TimeZoneField = React.forwardRef<HTMLSelectElement, SelectFieldProps>((props, ref) => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <SelectField
      ref={ref}
      label="Time zone"
      defaultValue=""
      help={<span>Your device's system time zone is <code>{currentTimeZone}</code>.</span>}
      {...props}
    >
      <option value="" disabled hidden>Time zone</option>

      {TIME_ZONES.map((tz) => (
        <option key={tz} value={tz}>{tz}</option>
      ))}
    </SelectField>
  );
});

export default TimeZoneField;
