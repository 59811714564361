import React, { useMemo, useEffect, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { USER_UPDATE, UserUpdateData, UserUpdateVariables } from '../../../lib/graphql/mutations/user/update';
import { User } from '../../../lib/graphql/types/user';
import UserFormatter from '../../../lib/graphql/formatters/user';

import toast from 'react-hot-toast';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { cleanEmpty } from '../../../lib/utils/form';
import InputField from '../../../components/fields/InputField';
import PhoneField from '../../../components/fields/PhoneField';
import AddressFields from '../../../components/fields/address/AddressFields';
import Switch from '../../../components/fields/Switch';
import Button from '../../../components/Button';

import parsePhoneNumber from 'libphonenumber-js';

interface Props {
  user: User;
}

interface FormValues {
  businessName: string;
  phone: string | null;
  address: {
    line1: string;
    line2: string | null;
    city: string;
    region: string;
    postalCode: string;
  } | null;
  mobileBusiness: boolean;
}

export default function BusinessInformationSection({ user }: Props) {
  const [updateUser] = useMutation<UserUpdateData, UserUpdateVariables>(USER_UPDATE, {
    onCompleted: () => {
      toast.success('Business information updated.');
    },
  });

  const defaultValues = useMemo(() => {
    const { businessName, phone, address } = user;

    return {
      businessName: businessName!,
      phone,
      address,
      mobileBusiness: address == null,
    };
  }, [user]);

  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(z.object({
      businessName: z.string().min(1, { message: 'Please enter your business name.' }),
      phone: z.string().nullish()
        .transform(cleanEmpty)
        .refine((phone) => {
          if (phone == null) { return true; }

          // If can't parse, return an error.
          if (parsePhoneNumber(phone!) != null) { return true; }
        }, 'Please enter a valid phone number.'),
      address: z.object({
        line1: z.string().min(1, { message: 'Please enter your address.' }).nullable(),
        line2: z.string().transform(cleanEmpty).nullable(),
        city: z.string().min(1, { message: 'Please enter your city.' }).nullable(),
        region: z.string().min(1, { message: 'Please select your region.' }).nullable(),
        postalCode: z.string().min(1, { message: 'Please enter your postal code.' }).nullable(),
      }).nullable(),
      mobileBusiness: z.boolean(),
    }).transform((data) => {
      // If mobile business, set address to null.
      if (data.mobileBusiness) {
        data.address = null;
      }

      return data;
    })),
  });

  const resetFields = useCallback(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  useEffect(() => {
    resetFields();
  }, [form.formState.isSubmitSuccessful, resetFields]);

  const handlePhoneChange = useCallback((phone: string) => {
    form.setValue('phone', phone, { shouldDirty: true });
  }, [form]);

  const onSubmit = async (input: FormValues) => {
    await updateUser({
      variables: {
        input: {
          businessName: input.businessName,
          phone: input.phone,
          address: input.address != null ? {
            ...input.address,
            country: user.country,
          } : null,
        },
      },
    });
  };

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Business information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          This information will be displayed publicly on your website.
        </p>
      </div>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2">
          <div className="px-4 py-6 sm:p-8 space-y-6">
            <InputField
              label="Business name"
              placeholder="Alex's Business"
              {...form.register('businessName')}
              type="text"
              required
              error={form.formState.errors.businessName?.message}
              disabled={user.bookingProvider != null}
              help={(() => {
                if (user.bookingProvider != null) {
                  const provider = new UserFormatter(user).bookingProvider;
                  return <span data-testid="business-name-input-help">You can only change your business name through {provider}.</span>;
                }

                return null;
              })()}
            />

            <PhoneField
              country={user.country}
              label="Phone number"
              name="phone"
              defaultValue={defaultValues.phone}
              onChange={handlePhoneChange}
              error={form.formState.errors.phone?.message}
              disabled={user.bookingProvider != null}
              help={(() => {
                if (user.bookingProvider != null) {
                  const provider = new UserFormatter(user).bookingProvider;
                  return <span data-testid="phone-input-help">You can only change your phone number through {provider}.</span>;
                }
              })()}
            />

            {(() => {
              if (user.bookingProvider) {
                const provider = new UserFormatter(user).bookingProvider;

                return (
                  <InputField
                    label="Address"
                    type="text"
                    value={user.address?.formatted}
                    disabled
                    help={`You can only change your address through ${provider}.`}
                    data-testid="address-readonly-input"
                  />
                );
              }

              return (
                <React.Fragment>
                  <AddressFields
                    name="address"
                    country={user.country}
                    disabled={form.watch('mobileBusiness')}
                    gridClassName="gap-y-6"
                  />

                  <Switch
                    id="mobile-business"
                    checked={form.watch('mobileBusiness')}
                    onToggle={(checked) => {
                      form.setValue('mobileBusiness', checked, { shouldValidate: true, shouldDirty: true });

                      if (checked) {
                        // Need to clear the address fields before setting the address to null.
                        form.setValue('address', { line1: '', line2: null, city: '', region: '', postalCode: '' }, { shouldDirty: true });
                        form.setValue('address', null, { shouldDirty: true });
                      } else {
                        form.setValue('address', user.address != null ? user.address : {
                          line1: '',
                          line2: '',
                          city: '',
                          region: '',
                          postalCode: '',
                        }, { shouldDirty: true });
                      }

                      // Clear any address errors.
                      form.clearErrors('address');
                    }}
                    error={form.formState.errors.mobileBusiness?.message}
                    data-testid="mobile-business-switch"
                  >
                    My business is mobile &amp; does not have a physical address.
                  </Switch>
                </React.Fragment>
              );
            })()}
          </div>
          <div className="flex items-center justify-end gap-x-2 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <Button
              color="white"
              size="sm"
              disabled={!form.formState.isDirty}
              onClick={resetFields}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="sm"
              loading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting || !form.formState.isDirty}
            >
              Save
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
