import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../fragments/user';
import { THEME_FIELDS } from '../../fragments/theme';
import { THEME_FILE_FIELDS } from '../../fragments/theme/file';

export const GET_THEME_FILES = gql`
  ${USER_FIELDS}
  ${THEME_FIELDS}
  ${THEME_FILE_FIELDS}

  query GetThemeFiles {
    viewer {
      ...UserFields
      theme {
        ...ThemeFields
        files {
          ...ThemeFileFields
        }
      }
    }
  }
`;
