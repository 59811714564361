import React, { useCallback, useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { USER_UPDATE, UserUpdateData, UserUpdateVariables } from '../../lib/graphql/mutations/user/update';
import { GET_INITIAL_DATA } from '../../Root';
import { Query } from '../../lib/graphql/types/query';
import { Country } from '../../lib/graphql/types/country';

import { useNavigate } from 'react-router-dom';
import paths from '../../paths';

import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { cleanEmpty } from '../../lib/utils/form';

import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import Loading from '../../components/Loading';
import DotPattern from '../../components/layout/DotPattern';
import Logo from '../../components/layout/Logo';
import InputField from '../../components/fields/InputField';
import CountryField from '../../components/fields/address/CountryField';
import PhoneField from '../../components/fields/PhoneField';
import AddressFields, { AddressValues } from '../../components/fields/address/AddressFields';
import Switch from '../../components/fields/Switch';
import Button from '../../components/Button';
import Footer from './components/Footer';

import parsePhoneNumber from 'libphonenumber-js';

interface FormValues {
  businessName: string;
  country: Country;
  phone: string | null;
  address: AddressValues | null;
  mobileBusiness: boolean;
}

export default function BusinessInformationPage() {
  const navigate = useNavigate();

  const [update] = useMutation<UserUpdateData, UserUpdateVariables>(USER_UPDATE);
  const form = useForm<FormValues>({
    resolver: zodResolver(z.object({
      businessName: z.string().min(1, { message: 'Please enter your business name.' }),
      country: z.string().min(1, { message: 'Please select your country.' }),
      phone: z.string().nullish()
        .transform(cleanEmpty)
        .refine((phone) => {
          if (phone == null) { return true; }

          // If can't parse, return an error.
          if (parsePhoneNumber(phone!) != null) { return true; }
        }, 'Please enter a valid phone number.'),
      address: z.object({
        line1: z.string().min(1, { message: 'Please enter your address.' }),
        line2: z.string().transform(cleanEmpty),
        city: z.string().min(1, { message: 'Please enter your city.' }),
        region: z.string().min(1, { message: 'Please select your region.' }),
        postalCode: z.string().min(1, { message: 'Please enter your postal code.' }),
      }).nullable(),
      mobileBusiness: z.boolean(),
    })),
    defaultValues: { country: 'US' },
  });

  const { data } = useQuery<Query>(GET_INITIAL_DATA);
  useEffect(() => {
    if (data == null) { return; }

    // Redirect to "select plan" page if no subscription.
    if (data.viewer!.billing == null) {
      return navigate(paths.onboarding.selectPlan);
    }

    // Set the initial data.
    form.reset({
      country: data.viewer!.country,
      mobileBusiness: false,
    });
  }, [data, navigate, form]);

  const handlePhoneChange = useCallback((phone: string) => {
    form.setValue('phone', phone);
  }, [form]);

  const resetAddressFields = useCallback(() => {
    const mobileBusiness = form.watch('mobileBusiness');

    // Reset the address fields first.
    form.setValue('address', {
      line1: '',
      line2: '',
      city: '',
      region: '',
      postalCode: '',
    });

    // If mobile business, set to null after clearing fields.
    if (mobileBusiness) {
      form.setValue('address', null);
    }

    // Clear any address errors.
    form.clearErrors('address');
  }, [form]);

  const onSubmit = async (input: FormValues) => {
    await update({
      variables: {
        input: {
          businessName: input.businessName,
          country: input.country,
          phone: input.phone,
          address: input.address != null ? {
            ...input.address,
            country: input.country,
          } : null,
        },
      },
      onCompleted: () => {
        navigate(paths.dashboard);
        toast.success("You're all set. Welcome to MyPrices.");
      },
      onError: () => null,
    });
  };

  if (data == null) { return <Loading className="h-full" />; }
  return (
    <React.Fragment>
      <Helmet>
        <title>Onboarding</title>
      </Helmet>

      <div className="flex flex-col min-h-full pt-16 lg:pt-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="flex-1 relative max-w-xl mx-auto">
          <DotPattern className="absolute left-full transform translate-x-1/2" />
          <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

          <Logo />

          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">
              Let's get some details.
            </h2>
            <p className="mt-4 sm:text-lg leading-6 text-gray-500">
              This information will be displayed on your website to your customers.
              You may change it at any time.
            </p>
          </div>
          <div className="mt-8">
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <InputField
                  label="Business name"
                  placeholder="Alex's Business"
                  {...form.register('businessName')}
                  type="text"
                  required
                  error={form.formState.errors.businessName?.message}
                />

                <CountryField
                  {...form.register('country', {
                    // Reset the address fields when the country changes.
                    onChange: () => resetAddressFields(),
                  })}
                  required
                  error={form.formState.errors.country?.message}
                />

                <PhoneField
                  country={form.watch('country')}
                  label="Phone number"
                  name="phone"
                  onChange={handlePhoneChange}
                  error={form.formState.errors.phone?.message}
                />

                <AddressFields
                  name="address"
                  country={form.watch('country')}
                  disabled={form.watch('mobileBusiness')}
                  gridClassName="gap-y-4"
                />

                <Switch
                  id="mobile-business"
                  checked={form.watch('mobileBusiness')}
                  onToggle={(checked) => {
                    form.setValue('mobileBusiness', checked, { shouldValidate: true });
                    resetAddressFields();
                  }}
                  error={form.formState.errors.mobileBusiness?.message}
                  data-testid="mobile-business-switch"
                >
                  My business is mobile &amp; does not have a physical address.
                </Switch>

                <Button type="submit" size="xl" fullWidth loading={form.formState.isSubmitting} disabled={form.formState.isSubmitting}>
                  Continue
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
