import React, { useCallback, useEffect, useRef } from 'react';

import { useMutation } from '@apollo/client';
import { SIGN_IN, SignInData, SignInVariables } from '../../lib/graphql/mutations/auth/sign-in';
import { SIGN_IN_OAUTH, SignInOAuthData, SignInOAuthVariables } from '../../lib/graphql/mutations/auth/sign-in-oauth';
import { User } from '../../lib/graphql/types/user';

import { Link, useNavigate } from 'react-router-dom';
import paths from '../../paths';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Helmet } from 'react-helmet';
import { useAuth } from '../../context/auth';
import Logo from '../../components/layout/Logo';
import InputField from '../../components/fields/InputField';
import Button from '../../components/Button';

import { GOOGLE_OAUTH_CLIENT_ID } from '../../lib/config';

interface FormValues {
  email: string;
  password: string;
}

export default function SignInPage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const onSignIn = useCallback(async ({ authenticationToken, user }: { authenticationToken: string; user: User; }) => {
    await auth.onSignIn({ authenticationToken, user });
    navigate(paths.dashboard);
  }, [auth, navigate]);

  const [signIn] = useMutation<SignInData, SignInVariables>(SIGN_IN, {
    onCompleted: async (data) => {
      const { authenticationToken, user } = data!.authSignIn;
      await onSignIn({ authenticationToken, user });
    },
    onError: () => null,
  });

  const [oauth] = useMutation<SignInOAuthData, SignInOAuthVariables>(SIGN_IN_OAUTH, {
    onCompleted: async (data) => {
      const { authenticationToken, user } = data!.authSignInOAuth;
      await onSignIn({ authenticationToken, user });
    },
    onError: () => null,
  });

  // Initialize OAuth buttons
  const oauthContainerRef = useRef<HTMLDivElement>(null);
  const signInWithGoogleContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Google
    google.accounts.id.initialize({
      client_id: GOOGLE_OAUTH_CLIENT_ID,
      callback: async (response: any) => {
        await oauth({
          variables: {
            input: { provider: 'GOOGLE', code: response.credential },
          },
        });
      },
    });

    google.accounts.id.renderButton(signInWithGoogleContainerRef.current!, {
      theme: 'outline',
      text: 'signin_with',
      width: oauthContainerRef.current!.offsetWidth,
    });
  }, [oauth, onSignIn]);

  const { handleSubmit, register, formState } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      email: z.string().min(1, { message: 'Please enter your email.' }).email({ message: 'Must be a valid email.' }),
      password: z.string().min(1, { message: 'Please enter your password.' }),
    })),
  });

  const onSubmit = async (input: FormValues) => {
    await signIn({ variables: { input } });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Log in</title>
      </Helmet>

      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Logo to={paths.auth.signIn} />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Log in to your account</h2>
              <p className="mt-2 text-sm text-gray-600">
                Or
                {' '}
                <Link to={paths.auth.signUp} className="font-medium text-black hover:text-neutral-900">
                  create a new account
                </Link>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div className="relative h-11" ref={oauthContainerRef}>
                  <div className="google-sign-in absolute inset-0" ref={signInWithGoogleContainerRef} />
                </div>

                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                  <InputField
                    label="Email"
                    placeholder="you@example.com"
                    {...register('email')}
                    type="email"
                    autoComplete="email"
                    required
                    error={formState.errors.email?.message}
                  />

                  <InputField
                    label="Password"
                    placeholder="Your password"
                    {...register('password')}
                    type="password"
                    autoComplete="current-password"
                    required
                    error={formState.errors.password?.message}
                  />

                  <div>
                    <Button type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting} fullWidth>
                      Log in
                    </Button>

                    <div className="text-center mt-2">
                      <Link to={paths.auth.forgotPassword} className="font-medium text-sm text-black hover:text-neutral-900">
                        Forgot your password?
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1637613345105-e6e721ffa9d4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt=""
          />
        </div>
      </div>
    </React.Fragment>
  );
}
