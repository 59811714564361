import client from '../../../lib/graphql/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/types/query';

import { redirect } from 'react-router-dom';
import paths from '../../../paths';

import signedInOnlyLoader from '../../../lib/router/loaders/signed-in-only';
import confirmedOnlyLoader from '../../../lib/router/loaders/confirmed-only';

// Requires that user is signed in, confirmed, on Pro plan,
// & does not already have a booking provider.
const loader = async (): Promise<Response | null> => {
  const signedInOnlyLoaderResponse = await signedInOnlyLoader();
  if (signedInOnlyLoaderResponse) { return signedInOnlyLoaderResponse; }

  const confirmedOnlyLoaderResponse = await confirmedOnlyLoader();
  if (confirmedOnlyLoaderResponse) { return confirmedOnlyLoaderResponse; }

  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });
  const { billing, bookingProvider } = data.viewer!;

  // Redirect to root if not on Pro plan or if already has booking provider.
  if (billing?.plan !== 'PRO' || bookingProvider != null) {
    return redirect(paths.root);
  }

  return null;
};

export default loader;
