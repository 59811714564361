import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
  OPEN_PERIOD_UPDATE_ALL,
  OpenPeriodUpdateAllData,
  OpenPeriodUpdateAllVariables,
} from '../../../../../lib/graphql/mutations/open-period/update-all';
import { GET_SETTINGS_DATA } from '../../../SettingsPage';
import { Query } from '../../../../../lib/graphql/types/query';
import { User } from '../../../../../lib/graphql/types/user';
import { Day } from '../../../../../lib/graphql/types/open-period';
import UserFormatter from '../../../../../lib/graphql/formatters/user';
import OpenPeriodFormatter from '../../../../../lib/graphql/formatters/open-period';

import InputField from '../../../../../components/fields/InputField';

import toast from 'react-hot-toast';
import { Dialog } from '@headlessui/react';
import { faXmark, faPlus } from '@fortawesome/pro-regular-svg-icons';
import Modal, { Props as ModalProps } from '../../../../../components/Modal';
import Icon from '../../../../../components/Icon';
import Button from '../../../../../components/Button';

import { produce } from 'immer';

type UpdateDayModalState = (
  { open: true; day: Day; }
    | { open: false; day?: Day; }
);

type UpdateDayModalAction = (
  { type: 'OPEN'; day: Day; }
    | { type: 'CLOSE'; }
);

export const updateDayModalReducer: React.Reducer<UpdateDayModalState, UpdateDayModalAction> = (state, action) => {
  switch (action.type) {
    case 'OPEN':
      return { open: true, day: action.day };
    case 'CLOSE':
      return { ...state, open: false };
  }
};

interface Props extends ModalProps {
  user: User;
  day?: Day;
}

export default function UpdateDayModal({ user, day, ...props }: Props) {
  const dayFormatted = OpenPeriodFormatter.day(day!) ?? '';

  type OpenPeriodInput = OpenPeriodUpdateAllVariables['input']['openPeriods'][number];
  const [periods, setPeriods] = useState<OpenPeriodInput[]>([]);
  useEffect(() => {
    setPeriods(
      user.openPeriods!
        .filter((period) => period.day === day)
        .map(({ day, start, end }) => ({ day, start, end })),
    );
  }, [user, day]);

  const [updateAll, { loading: submitting }] = useMutation<OpenPeriodUpdateAllData, OpenPeriodUpdateAllVariables>(OPEN_PERIOD_UPDATE_ALL, {
    variables: {
      input: {
        openPeriods: user.openPeriods!
          .filter((period) => period.day !== day)
          .map(({ day, start, end }) => ({ day, start, end }))
          .concat(periods),
      },
    },
    update: (cache, { data }) => {
      cache.updateQuery<Query>({ query: GET_SETTINGS_DATA }, (cachedData) => {
        return produce(cachedData!, (draft) => {
          draft.viewer!.openPeriods = data!.openPeriodUpdateAll.openPeriods;
        });
      });

      toast.success(`Updated the hours for ${dayFormatted}!`);
      props.onClose();
    },
  });

  const onClickNewPeriod = () => {
    setPeriods([
      ...periods,
      { day: day!, start: '09:00', end: '17:00' },
    ]);
  };

  return (
    <Modal {...props} className="max-w-xl" data-testid="update-day-modal">
      {(() => {
        if (user.bookingProvider != null) {
          return (
            <div data-testid="must-update-through-booking-provider-content">
              <div className="text-center">
                <Dialog.Title className="text-base font-medium leading-6 text-gray-900">
                  Must update through {UserFormatter.bookingProvider(user.bookingProvider)}
                </Dialog.Title>

                <Dialog.Description className="text-sm text-gray-500 mt-2">
                  Since your data is synced from {UserFormatter.bookingProvider(user.bookingProvider)}, you must update your business hours there.
                  When you do, it'll automatically update here.
                </Dialog.Description>
              </div>

              <div className="flex">
                <Button
                  className="flex-1 mt-4"
                  color="white"
                  onClick={props.onClose}
                >
                  Got it!
                </Button>
              </div>
            </div>
          );
        }

        return (
          <div data-testid="form-content">
            <Dialog.Title className="text-base font-medium leading-6 text-gray-900">
              {dayFormatted}'s hours
            </Dialog.Title>

            <div className="-mx-6 border-b border-gray-200">
              {(() => {
                if (periods.length === 0) {
                  return (
                    <div className="text-center py-8 px-6" data-testid="no-existing-periods-content">
                      <h3 className="text-base font-medium text-gray-900">
                        Closed all day
                      </h3>

                      <p className="mt-1 text-sm text-gray-500">
                        You haven't added any hours for {dayFormatted} yet.
                      </p>

                      <div className="flex justify-center">
                        <Button
                          className="mt-2"
                          size="sm"
                          color="white"
                          onClick={onClickNewPeriod}
                        >
                          Add hours
                        </Button>
                      </div>
                    </div>
                  );
                }

                return (
                  <div className="divide-y divide-gray-200">
                    {periods.map((day, index) => (
                      <div key={index} className="px-6 py-3 flex items-center" data-testid="open-period-cell">
                        <div className="flex-1 flex items-center">
                          <div className="flex-1">
                            <InputField
                              className="text-sm"
                              type="time"
                              value={day.start}
                              onChange={(event) => {
                                setPeriods(produce(periods, (draft) => {
                                  draft[index].start = event.target.value;
                                }));
                              }}
                              data-testid="start-input"
                            />
                          </div>

                          <div className="px-2 sm:px-4 text-sm">
                            to
                          </div>

                          <div className="flex-1">
                            <InputField
                              className="text-sm"
                              type="time"
                              value={day.end}
                              onChange={(event) => {
                                setPeriods(produce(periods, (draft) => {
                                  draft[index].end = event.target.value;
                                }));
                              }}
                              data-testid="end-input"
                            />
                          </div>
                        </div>

                        <div className="pl-4">
                          <button
                            className="flex items-center justify-center"
                            onClick={() => {
                              setPeriods(produce(periods, (draft) => {
                                draft.splice(index, 1);
                              }));
                            }}
                            data-testid="open-period-delete-button"
                          >
                            <Icon icon={faXmark} className="h-6 text-red-600" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })()}
            </div>

            {periods.length > 0 && (
              <Button
                rounded
                size="custom"
                color="gray"
                className="mt-4 py-1.5 px-3 text-xs"
                rightIcon={faPlus}
                onClick={onClickNewPeriod}
              >
                New period
              </Button>
            )}

            <div className="pt-4 flex gap-x-2 justify-end">
              <Button
                className="w-20"
                color="white"
                onClick={props.onClose}
              >
                Cancel
              </Button>

              <Button
                className="w-20"
                loading={submitting}
                disabled={submitting}
                onClick={() => updateAll()}
              >
                Save
              </Button>
            </div>
          </div>
        );
      })()}
    </Modal>
  );
}
