import React, { useReducer } from 'react';

import { User } from '../../../../lib/graphql/types/user';
import { DAYS } from '../../../../lib/graphql/types/open-period';

import DayCell from './components/DayCell';
import UpdateDayModal, { updateDayModalReducer } from './components/UpdateDayModal';

interface Props {
  user: User;
}

const OpenPeriodsSection = React.forwardRef<HTMLDivElement, Props>(({ user }, ref) => {
  const [updateDayModalState, updateDayModalDispatch] = useReducer(updateDayModalReducer, {
    open: false,
    day: undefined,
  });

  return (
    <React.Fragment>
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 lg:grid-cols-3" ref={ref}>
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Business hours</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            You guessed it, also displayed on your website.
          </p>
        </div>

        <div className="shadow-sm ring-1 ring-gray-900/5 rounded-md sm:rounded-xl lg:col-span-2 overflow-hidden">
          <div className="divide-y divide-gray-200">
            {DAYS.map((day) => (
              <DayCell
                key={day}
                day={day}
                periods={user.openPeriods!.filter((period) => period.day === day)}
                onClick={() => updateDayModalDispatch({ type: 'OPEN', day })}
              />
            ))}
          </div>
        </div>
      </div>

      <UpdateDayModal
        open={updateDayModalState.open}
        onClose={() => updateDayModalDispatch({ type: 'CLOSE' })}
        user={user}
        day={updateDayModalState.day}
      />
    </React.Fragment>
  );
});

export default OpenPeriodsSection;
