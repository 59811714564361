import React from 'react';

import { Domain } from '../../../../../../../lib/graphql/types/domain';

import DNSRecordCell from './components/DNSRecordCell';

import { MYPRICES_SITE_ADDRESSES } from '../../../../../../../lib/config';

interface Props {
  domain: Domain;
}

export default function DNSStatus({ domain }: Props) {
  return (
    <div className="divide-y divide-gray-200">
      <DNSRecordCell
        record={{
          connected: domain.dnsStatus.rootA1,
          name: '@',
          type: 'A',
          value: MYPRICES_SITE_ADDRESSES[0],
        }}
      />

      <DNSRecordCell
        record={{
          connected: domain.dnsStatus.rootA2,
          name: '@',
          type: 'A',
          value: MYPRICES_SITE_ADDRESSES[1],
        }}
      />

      {domain.dnsStatus.wwwA1 != null && (
        <DNSRecordCell
          record={{
            connected: domain.dnsStatus.wwwA1,
            name: 'www',
            type: 'A',
            value: MYPRICES_SITE_ADDRESSES[0],
          }}
          data-testid="dns-record-cell-www-a-1"
        />
      )}

      {domain.dnsStatus.wwwA2 != null && (
        <DNSRecordCell
          record={{
            connected: domain.dnsStatus.wwwA2,
            name: 'www',
            type: 'A',
            value: MYPRICES_SITE_ADDRESSES[1],
          }}
          data-testid="dns-record-cell-www-a-2"
        />
      )}

      <DNSRecordCell
        record={{
          connected: domain.dnsStatus.verificationTxt,
          name: '_myprices',
          type: 'TXT',
          value: domain.code,
        }}
      />
    </div>
  );
}
