import { gql } from '@apollo/client';
import { USER_FIELDS } from '../fragments/user';
import { CONCIERGE_REQUEST_FIELDS } from '../fragments/concierge-request';

export const GET_CONCIERGE_REQUESTS = gql`
  ${USER_FIELDS}
  ${CONCIERGE_REQUEST_FIELDS}

  query GetConciergeRequests {
    viewer {
      ...UserFields
      conciergeRequests {
        ...ConciergeRequestFields
      }
    }
  }
`;
