import React from 'react';

import toast from 'react-hot-toast';
import { BeatLoader } from 'react-spinners';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../../../../../../components/Icon';
import Badge from '../../../../../../../../components/Badge';

import classNames from 'classnames';
import colors from 'tailwindcss/colors';

export interface DNSRecord {
  connected: boolean;
  name: string;
  type: 'A' | 'TXT';
  value: string;
}

interface Props {
  record: DNSRecord;
}

export default function DNSRecordCell({ record, ...props }: Props & React.ComponentProps<'div'>) {
  return (
    <div {...props} className="flex items-center justify-between py-4 px-6">
      <div className="flex items-center space-x-4">
        <div>
          {(() => {
            if (record.connected) {
              return (
                <Icon
                  icon={faCheckCircle}
                  className="text-green-600"
                  size="lg"
                  data-testid="dns-record-cell-connected-icon"
                />
              );
            }

            return (
              <Icon
                icon={faTimesCircle}
                className="text-red-600"
                size="lg"
                data-testid="dns-record-cell-not-connected-icon"
              />
            );
          })()}
        </div>

        <div>
          <Badge
            color="gray"
            className="font-mono"
            onClick={async () => {
              await navigator.clipboard.writeText(record.name);
              toast.success('Copied to clipboard.');
            }}
            data-testid="dns-record-cell-name"
          >
            {record.name}
          </Badge>

          <p className="mt-2 text-xs text-gray-500" data-testid="dns-record-cell-type">
            {record.type}
          </p>
        </div>
      </div>

      <div className="text-right">
        <Badge
          color={record.connected ? 'green' : 'red'}
          className="font-mono"
          onClick={async () => {
            await navigator.clipboard.writeText(record.value);
            toast.success('Copied to clipboard.');
          }}
          data-testid="dns-record-cell-value"
        >
          {record.value}
        </Badge>

        <p
          className={classNames('flex items-center justify-end mt-2 text-xs whitespace-nowrap', {
            'text-green-600': record.connected,
            'text-red-600': !record.connected,
          })}
          data-testid="dns-record-cell-dns-status"
        >
          {!record.connected && (
            <BeatLoader
              color={colors.red[600]}
              size={6}
              margin={0}
              data-testid="dns-record-cell-waiting"
            />
          )}

          <span className="ml-2">{record.connected ? 'Connected' : 'Pending'}</span>
        </p>
      </div>
    </div>
  );
}
