import React from 'react';

import { Country } from '../../../lib/graphql/types/country';

import { AddressAutofill as MapboxAddressAutofill } from '@mapbox/search-js-react';
import { AddressAutofillFeatureSuggestion as MapboxFeature } from '@mapbox/search-js-core';

import { ENVIRONMENT } from '../../../lib/config';

interface Props {
  accessToken: string;
  country?: Country;
  onSelect: (feature: MapboxFeature) => void;
}

export default function AddressAutofill({ accessToken, country, onSelect, children }: React.PropsWithChildren<Props>) {
  // Disable in test environments since it crashes.
  if (ENVIRONMENT === 'test') {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }

  return (
    <MapboxAddressAutofill
      accessToken={accessToken}
      options={{ country }}
      onRetrieve={({ features }) => {
        onSelect(features[0]);
      }}
    >
      <React.Fragment>
        {children}
      </React.Fragment>
    </MapboxAddressAutofill>
  );
}
