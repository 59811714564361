import React, { useState } from 'react';

import { Domain } from '../../../../../../lib/graphql/types/domain';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../../../../components/Modal';
import DNSStatus from './DNSStatus';
import Button from '../../../../../../components/Button';
import DeleteDomainModal from './DeleteDomainModal';

interface Props extends ModalProps {
  domain?: Domain | null;
}

export default function DomainModal({ domain, ...props }: Props) {
  const [deleteDomainModalOpen, setDeleteDomainModalOpen] = useState(false);

  return (
    <React.Fragment>
      <Modal {...props} className="max-w-xl" data-testid="domain-modal">
        {(() => {
          if (domain == null) { return null; }

          return (
            <div data-testid="form-content">
              <Dialog.Title className="text-base font-medium leading-6 text-gray-900" data-testid="domain-modal-title">
                {domain.name}
              </Dialog.Title>

              <Dialog.Description className="text-sm text-gray-500 mt-0.5">
                Please add these DNS records to your domain. It usually takes around an hour for domains to update globally &amp; reach our servers.
              </Dialog.Description>

              <Button
                className="mt-2"
                color="red"
                size="sm"
                rounded
                onClick={() => setDeleteDomainModalOpen(true)}
              >
                Unlink
              </Button>

              <div className="mt-4 -mx-6 border-y border-gray-200">
                <DNSStatus domain={domain} />
              </div>

              <div className="pt-4 flex gap-x-2">
                <Button
                  className="flex-1"
                  color="white"
                  onClick={props.onClose}
                >
                  Close
                </Button>
              </div>
            </div>
          );
        })()}
      </Modal>

      {domain != null && (
        <DeleteDomainModal
          domain={domain}
          open={deleteDomainModalOpen}
          onClose={() => setDeleteDomainModalOpen(false)}
          onDeleted={() => {
            setDeleteDomainModalOpen(false);
            props.onClose();
          }}
        />
      )}
    </React.Fragment>
  );
}
