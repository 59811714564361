import { Currency } from '../graphql/types/currency';

export const flag = (code: Currency): string => {
  switch(code) {
    case 'USD':
      return '🇺🇸';
    case 'CAD':
      return '🇨🇦';
    case 'GBP':
      return '🇬🇧';
    case 'AUD':
      return '🇦🇺';
  }
};

interface FormatOptions {
  fromCents?: boolean;
  hideZeroCents?: boolean;
}

export const format = (value: number, currency: Currency, options?: FormatOptions) => {
  if (options?.fromCents) {
    value = (() => {
      switch (currency) {
        case 'USD':
        case 'CAD':
        case 'GBP':
        case 'AUD':
          return value / 100;
      }
    })();
  }

  const formatterOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  };

  if (options?.hideZeroCents && value % 1 === 0) {
    formatterOptions.minimumFractionDigits = 0;
    formatterOptions.maximumFractionDigits = 0;
  }

  const formatter = new Intl.NumberFormat('en-US', formatterOptions);
  return formatter.format(value);
};
