import { gql } from '@apollo/client';
import { PRICE_FIELDS } from '../fragments/price';
import { Currency } from '../types/currency';

export const GET_PRICES = gql`
  ${PRICE_FIELDS}

  query GetPrices($currency: Currency!) {
    prices(currency: $currency) {
      ...PriceFields
    }
  }
`;

export interface GetPricesVariables {
  currency: Currency;
}
