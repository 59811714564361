import React from 'react';

import { useMutation } from '@apollo/client';
import {
  SEND_RESET_PASSWORD_LINK,
  SendResetPasswordLinkData,
  SendResetPasswordLinkVariables,
} from '../../lib/graphql/mutations/auth/send-reset-password-link';

import { useNavigate } from 'react-router-dom';
import paths from '../../paths';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Helmet } from 'react-helmet';
import DotPattern from '../../components/layout/DotPattern';
import Logo from '../../components/layout/Logo';
import InputField from '../../components/fields/InputField';
import Button from '../../components/Button';

interface FormValues {
  email: string;
}

export default function ForgotPasswordPage() {
  const navigate = useNavigate();

  const [sendResetPasswordLink] = useMutation<SendResetPasswordLinkData, SendResetPasswordLinkVariables>(SEND_RESET_PASSWORD_LINK);
  const { handleSubmit, register, formState } = useForm<FormValues>({
    resolver: zodResolver(z.object({
      email: z.string().min(1, { message: 'Please enter your email.' }).email({ message: 'Must be a valid email.' }),
    })),
  });

  const onSubmit = async (input: FormValues) => {
    await sendResetPasswordLink({ variables: { input } });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Forgot your password?</title>
      </Helmet>

      <div className="min-h-full py-16 lg:py-24 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="relative max-w-xl mx-auto">
          <DotPattern className="absolute left-full transform translate-x-1/2" />
          <DotPattern className="absolute right-full bottom-0 transform -translate-x-1/2" />

          <Logo />

          {(() => {
            if (formState.isSubmitSuccessful) {
              return (
                <div data-testid="success-container">
                  <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Sent! ✉️</h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                      We just sent you an email with a link to reset your password.
                    </p>
                  </div>

                  <div className="mt-8">
                    <Button size="xl" fullWidth onClick={() => navigate(paths.auth.signIn)}>Go back to login</Button>
                  </div>
                </div>
              );
            }

            return (
              <div data-testid="form-container">
                <div className="text-center">
                  <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-12 lg:mt-8">Forgot your password?</h2>
                  <p className="mt-4 text-lg leading-6 text-gray-500">
                    No worries! We&apos;ll help you reset it.
                  </p>
                </div>
                <div className="mt-8">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                    <InputField
                      label="Email"
                      placeholder="Your email address"
                      type="email"
                      inputSize="lg"
                      {...register('email')}
                      error={formState.errors.email?.message}
                    />

                    <Button type="submit" size="xl" fullWidth loading={formState.isSubmitting} disabled={formState.isSubmitting}>
                      Send reset link
                    </Button>
                  </form>
                </div>
              </div>
            );
          })()}
        </div>
      </div>
    </React.Fragment>
  );
}
