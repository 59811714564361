import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: AuthResetPasswordInput!) {
    authResetPassword(input: $input) {
      success
    }
  }
`;

export interface ResetPasswordData {
  authResetPassword: {
    success: boolean;
  };
}

export interface ResetPasswordVariables {
  input: {
    token: string;
    password: string;
  };
}
