import { gql } from '@apollo/client';
import { OPEN_PERIOD_FIELDS } from '../../fragments/open-period';
import { OpenPeriod, Day } from '../../types/open-period';

export const OPEN_PERIOD_UPDATE_ALL = gql`
  ${OPEN_PERIOD_FIELDS}

  mutation OpenPeriodUpdateAll($input: OpenPeriodUpdateAllInput!) {
    openPeriodUpdateAll(input: $input) {
      openPeriods {
        ...OpenPeriodFields
      }
    }
  }
`;

export interface OpenPeriodUpdateAllData {
  openPeriodUpdateAll: {
    openPeriods: OpenPeriod[];
  };
}

export interface OpenPeriodUpdateAllVariables {
  input: {
    openPeriods: {
      day: Day;
      start: string;
      end: string;
    }[];
  };
}
