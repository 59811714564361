import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../fragments/user';
import { User } from '../../types/user';

export const SIGN_IN = gql`
  ${USER_FIELDS}

  mutation SignIn($input: AuthSignInInput!) {
    authSignIn(input: $input) {
      authenticationToken
      user {
        ...UserFields
      }
    }
  }
`;

export interface SignInData {
  authSignIn: {
    authenticationToken: string;
    user: User;
  };
}

export interface SignInVariables {
  input: {
    email: string;
    password: string;
  };
}
