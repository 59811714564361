import client from '../../lib/graphql/client';
import { GET_INITIAL_DATA } from '../../Root';
import { Query } from '../../lib/graphql/types/query';

import { redirect } from 'react-router-dom';
import paths from '../../paths';

import signedInOnlyLoader from '../../lib/router/loaders/signed-in-only';
import confirmedOnlyLoader from '../../lib/router/loaders/confirmed-only';

// Requires that user be confirmed & not already onboarded to render onboarding steps.
const loader = async (): Promise<Response | null> => {
  const signedInOnlyLoaderResponse = await signedInOnlyLoader();
  if (signedInOnlyLoaderResponse) { return signedInOnlyLoaderResponse; }

  const confirmedOnlyLoaderResponse = await confirmedOnlyLoader();
  if (confirmedOnlyLoaderResponse) { return confirmedOnlyLoaderResponse; }

  const { data } = await client.query<Query>({ query: GET_INITIAL_DATA });
  const { billing, businessName } = data.viewer!;

  // If already completed onboarding, redirect to dashboard.
  if (billing != null && businessName != null) {
    return redirect(paths.dashboard);
  }

  return null;
};

export default loader;
