import { gql } from '@apollo/client';
import { UNSPLASH_PHOTO_FIELDS } from '../../fragments/unsplash/photo';

export const SEARCH_PHOTOS = gql`
  ${UNSPLASH_PHOTO_FIELDS}

  query SearchUnsplashPhotos($query: String!) {
    unsplash {
      photos(query: $query) {
        ...UnsplashPhotoFields
      }
    }
  }
`;

export interface SearchPhotosVariables {
  query: string;
}
