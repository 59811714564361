import { gql } from '@apollo/client';

export const USER_DELETE = gql`
  mutation UserDelete {
    userDelete {
      enqueued
    }
  }
`;

export interface UserDeleteData {
  userDelete: {
    enqueued: boolean;
  };
}
