import { gql } from '@apollo/client';
import { USER_FIELDS } from '../../fragments/user';
import { User } from '../../types/user';

export const SIGN_UP = gql`
  ${USER_FIELDS}

  mutation SignUp($input: AuthSignUpInput!) {
    authSignUp(input: $input) {
      authenticationToken
      user {
        ...UserFields
      }
    }
  }
`;

export interface SignUpData {
  authSignUp: {
    authenticationToken: string;
    user: User;
  };
}

export interface SignUpVariables {
  input: {
    email: string;
    password: string;
    timeZone: string;
    agreesToTerms: boolean;
  };
}
