import { gql } from '@apollo/client';
import { THEME_SETTING_FIELDS } from '../../../fragments/theme/setting';
import { ThemeSetting, ThemeSettingDataMediaType } from '../../../types/theme/setting';

export const THEME_SETTING_UPDATE_ALL = gql`
  ${THEME_SETTING_FIELDS}

  mutation ThemeSettingUpdateAll($input: ThemeSettingUpdateAllInput!) {
    themeSettingUpdateAll(input: $input) {
      themeSettings {
        ...ThemeSettingFields
      }
    }
  }
`;

export interface ThemeSettingUpdateAllData {
  themeSettingUpdateAll: {
    themeSettings: ThemeSetting[];
  };
}

interface DataColorInput {
  color: string;
}

interface DataMediaInput {
  media: {
    type: ThemeSettingDataMediaType;
    url: string;
  };
}

interface DataTextInput {
  text: string;
}

export interface ThemeSettingUpdateAllVariables {
  input: {
    themeSettings: {
      id: string;
      data: DataColorInput | DataMediaInput | DataTextInput;
    }[];
  };
}
