import React, { useRef } from 'react';

import { PexelsVideo } from '../../../../../lib/graphql/types/pexels/video';

import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../../Icon';

interface Props {
  video: PexelsVideo;

  selected: boolean;
  onSelect: (video: PexelsVideo) => void;
}

export default function Video({ video, selected, onSelect }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <div
      onClick={() => onSelect(video)}
      className="border rounded-lg shadow-sm overflow-hidden cursor-pointer flex flex-col"
      onMouseEnter={() => videoRef.current!.play()}
      onMouseLeave={() => videoRef.current!.pause()}
      data-testid="video"
    >
      <div className="relative">
        <video
          ref={videoRef}
          src={video.source}
          poster={video.thumbnail}
          loop muted playsInline
          className="object-cover pointer-events-none select-none aspect-video"
          data-testid="video-element"
        />

        {selected && (
          <div className="absolute inset-0 bg-black/25 flex items-center justify-center">
            <Icon icon={faCheckCircle} className="text-white text-3xl" data-testid="selected-icon" />
          </div>
        )}
      </div>

      <div className="flex-1 flex items-center py-2 px-3 bg-white text-xs">
        <a href={`${video.user.url}?utm_source=MyPrices&utm_medium=referral`} target="_blank" rel="noreferrer" data-testid="attribution">
          By <span className="font-medium">{video.user.name}</span> on <span className="font-medium">Pexels</span>
        </a>
      </div>
    </div>
  );
}
