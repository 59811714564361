import { gql } from '@apollo/client';
import { CONCIERGE_REQUEST_FIELDS } from '../../fragments/concierge-request';
import { ConciergeRequest } from '../../types/concierge-request';

export const CONCIERGE_REQUEST_CREATE = gql`
  ${CONCIERGE_REQUEST_FIELDS}

  mutation ConciergeRequestCreate($input: ConciergeRequestCreateInput!) {
    conciergeRequestCreate(input: $input) {
      conciergeRequest {
        ...ConciergeRequestFields
      }
    }
  }
`;

export interface ConciergeRequestCreateData {
  conciergeRequestCreate: {
    conciergeRequest: ConciergeRequest;
  };
}

export interface ConciergeRequestCreateVariables {
  input: {
    description: string;
  };
}
