import React, { useReducer } from 'react';

import { useQuery } from '@apollo/client';
import { GET_INITIAL_DATA } from '../../../Root';
import { Query } from '../../../lib/graphql/types/query';

import { useMatches, useNavigate } from 'react-router-dom';
import paths from '../../../paths';

import {
  faHouse, faMoneyBillsSimple, faArrowUpRightFromSquare, faFiles,
  faCode, faSwatchbook, faBellConcierge, faSliders,
  faXmark, faLock,
} from '@fortawesome/pro-regular-svg-icons';
import { Dialog, Transition } from '@headlessui/react';
import { BeatLoader } from 'react-spinners';
import Icon, { Props as IconProps } from '../../../components/Icon';
import Logo from '../../../components/layout/Logo';
import ProRequiredModal, { proRequiredModalReducer } from './ProRequiredModal';

import classNames from 'classnames';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function SideMenu(props: Props) {
  const navigate = useNavigate();

  const [
    proRequiredModalState,
    proRequiredModalDispatch,
  ] = useReducer(proRequiredModalReducer, { open: false, description: undefined });

  const { data, loading } = useQuery<Query>(GET_INITIAL_DATA);

  if (loading) {
    return null;
  }

  const navigation: Array<{ title?: string; items: ItemProps[] }> = [
    {
      items: [
        { name: 'Dashboard', icon: faHouse, to: paths.dashboard },
        { name: 'Prices', icon: faMoneyBillsSimple, to: paths.services.index },
      ],
    },
    {
      title: 'Website',
      items: [
        {
          name: 'View website',
          icon: faArrowUpRightFromSquare,
          onClick: () => {
            window.open(data!.viewer!.websiteUrl!, '_blank')!.focus();
          },
        },
        {
          name: 'Pages',
          icon: faFiles,
          to: paths.website.pages.index,
          onClick: () => {
            if (data!.viewer!.billing!.plan !== 'PRO') {
              proRequiredModalDispatch({
                type: 'OPEN',
                description: 'Custom website pages are only available on the Pro plan.',
              });

              return;
            }

            navigate(paths.website.pages.index);
          },
          locked: data!.viewer!.billing!.plan !== 'PRO',
        },
        { name: 'Customize', icon: faSwatchbook, to: paths.website.customize },
        { name: 'Concierge', icon: faBellConcierge, to: paths.website.concierge.index },
        {
          name: 'Code editor',
          icon: faCode,
          to: paths.website.codeEditor,
          onClick: () => {
            if (data!.viewer!.billing!.plan !== 'PRO') {
              proRequiredModalDispatch({
                type: 'OPEN',
                description: 'The code editor is only available on the Pro plan.',
              });

              return;
            }

            navigate(paths.website.codeEditor);
          },
          locked: data!.viewer!.billing!.plan !== 'PRO',
        },
      ],
    },
    {
      title: 'Account',
      items: [
        { name: 'Settings', icon: faSliders, to: paths.settings },
      ],
    },
  ];

  return (
    <React.Fragment>
      <Transition.Root show={props.open} as={React.Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={() => props.setOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-black">
              <Transition.Child
                as={React.Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <Icon icon={faXmark} className="h-6 w-6 text-white" />
                  </button>
                </div>
              </Transition.Child>

              <div className="shrink-0 flex items-center pt-4 px-6">
                <Logo className="h-10 invert" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto pb-5">
                <nav className="px-2 space-y-5 select-none">
                  {navigation.map((section, index) => (
                    <div key={index} className="space-y-2">
                      {section.title &&
                        <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                          {section.title}
                        </h3>
                      }

                      <div className="space-y-1" role="group">
                        {section.items.map((item, i) => <Item key={i} {...item} />)}
                      </div>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-y-0 z-20">
        <div className="flex flex-col grow pt-8 bg-black shadow-2xl overflow-y-auto">
          <div className="flex items-center shrink-0 px-6">
            <Logo className="h-9 invert" />
          </div>
          <div className="mt-5 grow flex flex-col">
            <nav className="flex-1 px-2 pb-4 space-y-5 select-none">
              {navigation.map((section, index) => (
                <div key={index} className="space-y-2">
                  {section.title &&
                    <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">
                      {section.title}
                    </h3>
                  }

                  <div className="space-y-1" role="group">
                    {section.items.map((item, i) => <Item key={i} {...item} />)}
                  </div>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <ProRequiredModal
        open={proRequiredModalState.open}
        onClose={() => proRequiredModalDispatch({ type: 'CLOSE' })}
        description={proRequiredModalState.description ?? ''}
      />
    </React.Fragment>
  );
}

interface ItemProps {
  to?: string;
  onClick?: () => void;

  icon: IconProps['icon'];
  name: string;
  loading?: boolean;
  locked?: boolean;
}

function Item({ icon, name, loading, locked, ...props }: ItemProps) {
  const navigate = useNavigate();
  const matches = useMatches();
  const active = props.to != null && matches.find((match) => {
    return match.pathname.includes(props.to!);
  }) != null;

  const renderContent = () => {
    if (loading) {
      return (
        <div className="my-0.5">
          <BeatLoader color="white" size={8} margin={0} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Icon
          icon={icon}
          className={classNames(
            'shrink-0 h-5 w-5 mr-4 md:mr-3 text-gray-400',
            { '!text-white': active },
          )}
        />

        <span className="mt-0.5">{name}</span>

        {locked && (
          <Icon
            icon={faLock}
            className="shrink-0 h-4 w-4 ml-auto text-gray-500"
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <div
      onClick={() => {
        if (props.onClick) { return props.onClick(); }
        return navigate(props.to!);
      }}
      className={classNames(
        'cursor-pointer group flex items-center px-3 py-2 font-medium',
        'rounded-md text-gray-400 hover:bg-zinc-900 text-base md:text-sm',
        {
          'justify-center': loading,
          'bg-zinc-900 !text-white': active || loading,
        },
      )}
    >
      {renderContent()}
    </div>
  );
}
