import { User } from './graphql/types/user';

export class AuthStorage {
  static BEARER_TOKEN_KEY = 'bearer-token';

  static get bearerToken() {
    return localStorage.getItem(this.BEARER_TOKEN_KEY);
  }

  static setBearerToken({ authenticationToken, user }: { authenticationToken: string; user: User; }) {
    localStorage.setItem(this.BEARER_TOKEN_KEY, `${user.id}:${authenticationToken}`);
  }

  static removeBearerToken() {
    localStorage.removeItem(this.BEARER_TOKEN_KEY);
  }
}
