import React, { useMemo, useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
  UNSPLASH_PHOTO_TRACK_DOWNLOAD,
  UnsplashPhotoTrackDownloadData,
  UnsplashPhotoTrackDownloadVariables,
} from '../../../../lib/graphql/mutations/unsplash/photo/track-download';

import { Dialog } from '@headlessui/react';
import Modal, { Props as ModalProps } from '../../../Modal';
import PillTabs from '../../../PillTabs';
import UploadTab from './UploadTab';
import StockPhotoTab from './StockPhotoTab';
import StockVideoTab from './StockVideoTab';
import Button from '../../../Button';

import { MediaValue } from '../';

interface Props extends ModalProps {
  onSubmit: (value: MediaValue) => void;
}

interface BaseTabState {
  value?: MediaValue;
}

interface UploadTabState extends BaseTabState {
  tab: 'upload';
  file?: File | null;
};

interface StockPhotoTabState extends BaseTabState {
  tab: 'stock-photo';
  selected?: string | null;
};

interface StockVideoTabState extends BaseTabState {
  tab: 'stock-video';
  selected?: string | null;
};

type ModalState = (UploadTabState | StockPhotoTabState | StockVideoTabState);

export default function MediaFieldModal({ onSubmit, ...props }: Props) {
  const [unsplashPhotoTrackDownload] = useMutation<
    UnsplashPhotoTrackDownloadData,
    UnsplashPhotoTrackDownloadVariables
  >(UNSPLASH_PHOTO_TRACK_DOWNLOAD);

  const defaultModalState = useMemo<ModalState>(() => ({
    tab: 'upload',
  }), []);

  const [modalState, setModalState] = useState<ModalState>(defaultModalState);

  // Reset the modal state when opening.
  useEffect(() => {
    if (props.open) {
      setModalState(defaultModalState);
    }
  }, [props.open, defaultModalState]);

  const onClickSave = () => {
    onSubmit(modalState.value!);

    // Track download if a stock photo is selected.
    if (modalState.tab === 'stock-photo') {
      unsplashPhotoTrackDownload({
        variables: {
          input: {
            id: modalState.selected!,
          },
        },
      });
    }
  };

  return (
    <Modal {...props} className="max-w-xl" data-testid="media-field-modal">
      <div>
        <Dialog.Title className="text-base font-medium leading-6 text-gray-900">
          Media library
        </Dialog.Title>
      </div>

      <div className="mt-2">
        <PillTabs
          fullwidth={true}
          panelClassName="pt-4"
          selectedKey={modalState.tab}
          onChange={(key) => {
            setModalState({ tab: key as ModalState['tab'] });
          }}
          tabs={[
            {
              key: 'upload',
              title: 'Upload a file',
              render: () => {
                if (modalState.tab !== 'upload') { return null; }

                return (
                  <UploadTab
                    file={modalState.file}
                    onChange={(state) => {
                      setModalState({
                        tab: 'upload',
                        file: state.file,
                        value: state.value,
                      });
                    }}
                    reset={() => setModalState({ tab: 'upload' })}
                  />
                );
              },
              'data-testid': 'upload-tab',
            },
            {
              key: 'stock-photo',
              title: 'Stock photo',
              render: () => {
                if (modalState.tab !== 'stock-photo') { return null; }

                return (
                  <StockPhotoTab
                    selected={modalState.selected}
                    onChange={(state) => {
                      setModalState({
                        tab: 'stock-photo',
                        selected: state.selected,
                        value: state.value,
                      });
                    }}
                    reset={() => setModalState({ tab: 'stock-photo' })}
                  />
                );
              },
              'data-testid': 'stock-photo-tab',
            },
            {
              key: 'stock-video',
              title: 'Stock video',
              render: () => {
                if (modalState.tab !== 'stock-video') { return null; }

                return (
                  <StockVideoTab
                    selected={modalState.selected}
                    onChange={(state) => {
                      setModalState({
                        tab: 'stock-video',
                        selected: state.selected,
                        value: state.value,
                      });
                    }}
                    reset={() => setModalState({ tab: 'stock-video' })}
                  />
                );
              },
              'data-testid': 'stock-video-tab',
            },
          ]}
        />
      </div>

      <div className="mt-4 flex gap-x-2">
        <Button
          className="flex-1"
          color="white"
          onClick={props.onClose}
        >
          Cancel
        </Button>

        <Button
          color="green"
          className="flex-1"
          disabled={modalState.value == null}
          onClick={onClickSave}
          data-testid="media-field-modal-save-button"
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
